import { isMobile } from "react-device-detect"
import { styled } from "twin.macro"

import { css } from "@emotion/react"

interface ContainerProps {
    className?: string
}

/**
 * @deprecated
 * use Container v2 instead
 */
const Container: React.FC<ContainerProps> = styled.div`
    width: 90%;
    min-width: 1210px;
    max-width: 1400px;

    box-sizing: border-box;
    margin: 0px auto;
    padding: 0px 20px;

    ${isMobile &&
    css`
        @media screen and (max-width: 1024px) {
            min-width: -webkit-fill-available;
        }
    `}
`

export default Container
