import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchVirtualAccount = createAsyncThunk<VirtualAccountAPI[]>("banks/fetchVirtualAccount", async () => {
    const response = await fetchApi({
        url: "/va",
        method: "GET"
    })

    return response
})
