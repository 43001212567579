import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getAuth } from "@helpers/auth"
import { fetchVerifyPhone } from "./action"

const auth = getAuth()

const verifyPhoneSlicer = createSlice({
    name: "verifyPhone",
    initialState: {
        token: auth.token || null,
        smstoken: null,
        phonenum: null,
        isLoading: false
    } as unknown as VerifyPhoneModel,
    reducers: {},
    extraReducers: {
        [fetchVerifyPhone.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchVerifyPhone.fulfilled.type]: (state, action: PayloadAction<VerifyPhoneModel>) => {
            state.token = action.payload?.token
            state.smstoken = action.payload?.smstoken
            state.phonenum = action.payload?.phonenum
            state.isLoading = false
        },
        [fetchVerifyPhone.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default verifyPhoneSlicer
