/* eslint-disable import/no-cycle */
/* eslint-disable simple-import-sort/imports */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { resetAuth, setAuth } from "@helpers/auth"
// eslint-disable-next-line import/no-cycle
import { baseFetchApiV3 } from "@api/baseApi"
import { EVENT_LOGOUT } from "@const/analytics-event"
import { clearUserProfile, updateUserEmail, updateUserId } from "@redux/profile/slicer"

// eslint-disable-next-line @typescript-eslint/default-param-last
export const logout = createAsyncThunk("auth/logout", async (force: boolean, { dispatch }) => {
    if (!force) {
        await baseFetchApiV3<SuccessAPI>({
            url: "logout",
            method: "POST"
        })
    }

    resetAuth()
    dispatch(clearUserProfile())

    const { default: analytics } = await import("@lib/analytics")
    analytics.track(EVENT_LOGOUT)

    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
    ;(moengageAnalytic.plugins as any).moengage.destroySession()

    return true
})

export const register = createAsyncThunk(
    "auth/register",
    async (payload: RegisterPayload, { dispatch, rejectWithValue }) => {
        try {
            const response = await baseFetchApiV3<SuccessAPI>({
                url: "/register",
                method: "POST",
                data: payload
            })

            if (payload?.user_email) {
                dispatch(updateUserEmail(payload.user_email))
            }

            setAuth({
                token: response.result?.token,
                hash: response.result?.hash
            })

            return {
                ...response.result,
                ...payload
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const resendOtp = createAsyncThunk("auth/resendOtp", async (payload: ResendOtpPayload) => {
    const response = await baseFetchApiV3<SuccessAPI>({
        url: "otp/resend",
        method: "POST",
        data: payload
    })

    return response?.result
})

export const verificationOtp = createAsyncThunk("auth/ververificationOtp", async (pasyload: VerifyOTPProps) => {
    const response = await baseFetchApiV3<SuccessAPI>({
        url: "register/verify-otp",
        method: "POST",
        data: pasyload
    })

    setAuth({
        token: response.result?.token,
        hash: response.result?.hash
    })

    if (response.result?.uid) {
        updateUserId(response.result.uid)
    }

    return response?.result
})

interface Result {
    token: string
    hash: string
    uid: number
}

export const login = createAsyncThunk("auth/login", async (payload: LoginPayload, { dispatch }) => {
    const response = await baseFetchApiV3<SuccessAPI<Result>>({
        url: "/login",
        method: "POST",
        data: payload
    })

    if (response.result?.uid) {
        dispatch(updateUserId(response.result.uid))
    }

    setAuth({
        token: response.result?.token,
        hash: response.result?.hash
    })

    return response.result
})

export const login2fa = createAsyncThunk("auth/login2fa", async (payload: Login2faPayload) => {
    const response = await baseFetchApiV3<SuccessAPI<Result>>({
        url: "/2fa/auth",
        method: "POST",
        data: payload
    })

    setAuth({
        token: response.result?.token,
        hash: response.result?.hash
    })

    return response.result
})

export const loginCallback = createAsyncThunk(
    "auth/loginCallback",
    async (payload: LoginCallbackPayload, { dispatch }) => {
        const response = await baseFetchApiV3<SuccessAPI>({
            url: "/login/callback",
            method: "POST",
            data: payload
        })

        if (response.result?.uid) {
            dispatch(updateUserId(response.result.uid))
        }

        setAuth({
            token: response.result?.token,
            hash: response.result?.hash
        })

        return response.result
    }
)

export const loginGoogle = createAsyncThunk("auth/loginGoogle", (payload: LoginGooglePayload, { dispatch }) => {
    if (payload?.uid) {
        dispatch(updateUserId(payload.uid))
    }

    setAuth({
        token: payload.token,
        hash: payload.hash
    })

    return payload
})

export const loginApple = createAsyncThunk<LoginAPI, string>("auth/loginApple", async (appleIdToken, { dispatch }) => {
    const response = await baseFetchApiV3<BaseResponseAPIV3<LoginAPI>>({
        url: "/sso/apple",
        method: "POST",
        data: {
            apple_id_token: appleIdToken
        },
        headers: {
            "Content-Type": "application/json"
        }
    })

    if (response.result?.uid) {
        dispatch(updateUserId(response.result.uid))
    }

    setAuth({
        token: response.result.token,
        hash: response.result.hash
    })

    return response.result
})
