import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getAuth } from "@helpers/auth"
import { fetchSetPrimaryPhone } from "./action"

const auth = getAuth()

const setPrimaryPhoneSlicer = createSlice({
    name: "setPrimaryPhone",
    initialState: {
        token: auth.token || null,
        smstoken: null,
        phonenum: null,
        isLoading: false
    } as unknown as VerifyPhoneModel,
    reducers: {},
    extraReducers: {
        [fetchSetPrimaryPhone.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchSetPrimaryPhone.fulfilled.type]: (state, action: PayloadAction<VerifyPhoneModel>) => {
            state.token = action.payload?.token
            state.smstoken = action.payload?.smstoken
            state.phonenum = action.payload?.phonenum
            state.isLoading = false
        },
        [fetchSetPrimaryPhone.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default setPrimaryPhoneSlicer
