import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchCountryCodes } from "./actions"

const countryCodesSlicer = createSlice({
    name: "countryCodes",
    initialState: {
        countryCodes: null as CountryCodesAPI | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchCountryCodes.fulfilled.type]: (state, action: PayloadAction<CountryCodesAPI>) => {
            state.countryCodes = action.payload
            state.isLoading = false
        }
    }
})

export default countryCodesSlicer
