import { Cookies } from "react-cookie"
import { COOKIE_DOMAIN, COOKIE_PREFIX } from "@config/config"

let cookie = new Cookies()

interface Options {
    path?: string
    maxAge?: number
    domain?: string
}

let defaultOptions: Options = {
    path: "/",
    maxAge: 86400
}

if (COOKIE_DOMAIN) {
    defaultOptions = Object.assign(defaultOptions, {
        domain: `.${COOKIE_DOMAIN}`
    })
}

const formatCookieName = (name: string) => `${COOKIE_PREFIX ?? ""}${name}`

export const getCookie = (name: string) => cookie.get(formatCookieName(name), defaultOptions as any)
export const setCookie = (name: string, value: string, options?: Options) =>
    cookie.set(formatCookieName(name), value, { ...defaultOptions, ...options })
export const removeCookie = (name: string) => cookie.remove(formatCookieName(name), defaultOptions)

export const initialCookie = (headers?: string) => {
    cookie = new Cookies(headers)
}
