/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchCoinInfo } from "./actions"

const coinInfoSlicer = createSlice({
    name: "coinInfo",
    initialState: {
        coinInfo: null as CoinInfoModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchCoinInfo.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCoinInfo.fulfilled.type]: (state, action: PayloadAction<CoinInfoAPI>) => {
            state.coinInfo = {
                id: action.payload.result.id,
                detail: action.payload.result.detail,
                name: action.payload.result.name,
                code: action.payload.result.code,
                max_supply: action.payload.result.max_supply,
                circulating_supply: action.payload.result.circulating_supply,
                total_supply: action.payload.result.total_supply
            }

            state.isLoading = false
        },
        [fetchCoinInfo.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default coinInfoSlicer
