import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import { i18n } from "next-i18next"
import classNames from "classnames"
import * as ReactDOM from "react-dom"
import { Transition, TransitionStatus } from "react-transition-group"
import tw, { styled } from "twin.macro"

import { When } from "@components/If"
import Illustration from "@components/Illustrations"
import Overlay from "@components/Overlay"
import { DARKMODE_COOKIE_KEY } from "@config/darkmode"
import { MODAL } from "@const/z-index"
import { css } from "@emotion/css"
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import { getCookie } from "@helpers/cookies"
import darkTheme from "@styles/themes/dark"
import lightTheme from "@styles/themes/light"
import { isBrowser } from "@utils/browser"
import { scrollbarSize } from "@utils/scrollbar"

import { Button } from "../Button"
import CustomScroll, { CustomScrollRef } from "../CustomScroll"
import Icons from "../Icons"

const defaultWidth = 420

interface AnimationProps {
    state: TransitionStatus
}

const Animation = styled.div<AnimationProps>`
    ${tw`h-full`}
    transition: margin-top 300ms, opacity 300ms;

    transition-property: margin-top, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    margin-top: ${({ state }) => {
        switch (state) {
            case "entering":
                return "-200px"
            case "entered":
                return "0px"
            case "exiting":
                return "-200px"
            case "exited":
                return "-200px"
            default:
                return "-200px"
        }
    }};

    opacity: ${({ state }) => {
        switch (state) {
            case "entering":
                return 0
            case "entered":
                return 1
            case "exiting":
                return 0
            case "exited":
                return 0
            default:
                return 0
        }
    }};
`

interface WrapperProps {
    fullScreen?: boolean
}

const Wrapper = styled.div<WrapperProps>`
    ${tw`
        py-[75px] px-0
        min-h-full
        flex items-center justify-center
    `}

    ${({ fullScreen }) => fullScreen && tw`py-0`}
`

interface ModalDialogProps {
    width: number
    minHeight?: number
    fullScreen?: boolean
}
const ModalDialog = styled.div<ModalDialogProps>`
    width: ${({ width }) => `${width}px`};
    min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "auto")};

    ${tw`
        relative
        overflow-hidden
        transition duration-300 ease-in-out
        rounded-3xl
        bg-background dark:bg-dark-background
        shadow-modal dark:shadow-dark-modal
    `}

    ${({ fullScreen }) => fullScreen && tw`w-screen h-screen rounded-none`}
`

export const ModalHeader = tw.div`
    p-6 pb-0 relative
`

export const ModalFooter = tw.div`
    p-6
`

export const ModalBody = tw.div`w-full`

const elModalRoot = isBrowser && document.createElement("div")

if (elModalRoot) {
    elModalRoot.id = "modal-root"
    document.body.appendChild(elModalRoot)
}

export interface ModalProps extends React.PropsWithChildren<{}> {
    /**
     * Whether the modal dialog is open or not
     */
    open: boolean
    /**
     * Specify a function that will be called when a user clicks overlay and close button on top right
     */
    onClose: () => void
    /**
     * Set header of modal
     */
    header?: React.ReactNode
    /**
     * Set footer of modal
     *
     */
    footer?: React.ReactNode
    /**
     * Add close button on top right of modal
     * @default false
     *
     */
    withCloseButton?: boolean
    /**
     * Disable close modal when click on backdrop
     * @default false
     *
     */
    disableBackdropClick?: boolean
    /**
     * Width of the modal dialog
     */
    width?: number
    /**
     * Height of the modal dialog
     */
    height?: number
    /**
     * Min height of the modal dialog
     */
    minHeight?: number
    /**
     * Max height of the modal body
     */
    maxHeight?: number
    /**
     * Modal dialog class name
     */
    modalDialogClassName?: string
    /**
     * Modal header class name
     */
    modalHeaderClassName?: string
    /**
     * Modal Body class name
     */
    modalBodyClassName?: string
    /**
     * Modal Footer class name
     */
    modalFooterClassName?: string
    /**
     * Set body scroll ref
     */
    bodyScrollRef?: MutableRefObject<CustomScrollRef>
    /**
     * Set modal size to full screen
     */
    fullScreen?: boolean
    /**
     * Set z index
     */
    order?: number
}

type MethodVariant = "Success" | "Failed" | "Warning" | "None"
type MethodOptions = Omit<ModalProps, "open">
interface Content {
    title: React.ReactNode
    description?: React.ReactNode
}

interface ModalMethodProps {
    show: (content: Content, variant: MethodVariant, options?: MethodOptions) => void
    success: (content: Content, options?: MethodOptions) => void
    error: (content: Content, options?: MethodOptions) => void
    warning: (content: Content, options?: MethodOptions) => void
}

const Modal: React.FC<ModalProps> & ModalMethodProps = ({
    open,
    header,
    footer,
    withCloseButton,
    disableBackdropClick,
    width = defaultWidth,
    height,
    minHeight,
    maxHeight,
    modalDialogClassName,
    modalHeaderClassName,
    modalBodyClassName,
    modalFooterClassName,
    bodyScrollRef,
    fullScreen,
    order: orderProp,
    onClose,
    children
}: ModalProps) => {
    const modalHeaderRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const modalFooterRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const [headerHeight, setHeaderHeight] = useState<number | null>(0)
    const [footerHeight, setFooterHeight] = useState<number | null>(0)

    const order = useRef(orderProp || MODAL)

    const handleEnter = () => {
        if (!elModalRoot) {
            return
        }

        order.current = order.current + elModalRoot.childElementCount - 1

        document.body.style.overflowY = "hidden"
        document.body.style.paddingRight = `${scrollbarSize()}px`
    }

    const handleExited = () => {
        if (!elModalRoot) {
            return
        }

        const lastModal = elModalRoot.childElementCount <= 1

        if (lastModal) {
            document.body.style.removeProperty("overflow-y")
            document.body.style.removeProperty("padding-right")
        }
    }

    useEffect(() => {
        if (headerHeight) {
            return
        }

        if (modalHeaderRef.current) {
            setHeaderHeight(modalHeaderRef.current?.offsetHeight || 0)
        }
    }, [headerHeight, open])

    useEffect(() => {
        if (footerHeight) {
            return
        }

        if (modalFooterRef.current) {
            setFooterHeight(modalFooterRef.current?.offsetHeight || 0)
        }
    }, [footerHeight, open])

    useEffect(() => {
        if (open) {
            handleEnter()
        }

        return () => {
            if (open) {
                handleExited()
            }
        }
    }, [open])

    const onClickBackdrop = () => {
        if (!disableBackdropClick) {
            onClose()
        }
    }

    if (!elModalRoot) {
        return null
    }

    return ReactDOM.createPortal(
        <Overlay in={open} onClick={onClickBackdrop} order={order.current}>
            <Transition in={open} timeout={300} mountOnEnter unmountOnExit>
                {(state) => (
                    <Animation state={state}>
                        <Wrapper fullScreen={fullScreen}>
                            <ModalDialog
                                width={width}
                                minHeight={minHeight}
                                className={modalDialogClassName}
                                fullScreen={fullScreen}
                                onClick={(event) => event.stopPropagation()}
                            >
                                <When condition={withCloseButton}>
                                    <Icons
                                        icon='XClose'
                                        width={24}
                                        height={24}
                                        wrapperClassname='absolute right-6 top-6 z-[99]'
                                        className='text-icon-contrast dark:text-dark-icon-contrast cursor-pointer'
                                        onClick={onClose}
                                    />
                                </When>
                                <When condition={header}>
                                    <ModalHeader ref={modalHeaderRef} className={modalHeaderClassName}>
                                        {header}
                                    </ModalHeader>
                                </When>
                                <CustomScroll ref={bodyScrollRef}>
                                    <ModalBody
                                        className={classNames(
                                            modalBodyClassName,
                                            css`
                                                ${height &&
                                                `height: calc(${height}px - ${headerHeight}px - ${footerHeight}px);`}
                                                ${maxHeight &&
                                                `max-height: calc(${maxHeight}px - ${headerHeight}px - ${footerHeight}px);`}
                                                ${fullScreen &&
                                                `height: calc(100vh - ${headerHeight}px - ${footerHeight}px);`}
                                            `
                                        )}
                                    >
                                        {children}
                                    </ModalBody>
                                </CustomScroll>
                                <When condition={footer}>
                                    <ModalFooter ref={modalFooterRef} className={modalFooterClassName}>
                                        {footer}
                                    </ModalFooter>
                                </When>
                            </ModalDialog>
                        </Wrapper>
                    </Animation>
                )}
            </Transition>
        </Overlay>,
        elModalRoot
    )
}

Modal.show = ({ title, description }, variant, optionsProps) => {
    /** This code to prevent overidding onClose callback */
    let options = optionsProps as Omit<MethodOptions, "onClose">
    let onClose = optionsProps?.onClose

    if (optionsProps) {
        const { onClose: onCloseProp, ...rest } = optionsProps
        options = rest
        onClose = onCloseProp
    }
    /** This code to prevent overidding onClose callback */

    const elModal = document.createElement("div")
    elModal.setAttribute("id", "modal-root")
    document.body.appendChild(elModal)

    const isMobile = window.innerWidth < 1280
    const isDarkMode = getCookie(DARKMODE_COOKIE_KEY)
    const theme = Number(isDarkMode) ? darkTheme : lightTheme

    const handleClose = () => {
        onClose?.()

        ReactDOM.unmountComponentAtNode(elModal)
        document.body.removeChild(elModal)
    }

    ReactDOM.render(
        <EmotionThemeProvider theme={theme}>
            <Modal
                open
                onClose={handleClose}
                withCloseButton
                width={isMobile ? 328 : defaultWidth}
                header={
                    <div className='flex flex-col justify-center items-center gap-4 w-full'>
                        {variant !== "None" && <Illustration name={variant} width={88} height={88} />}
                        <div className='flex flex-col gap-1 !w-full'>
                            <span className='title-3 xl:title-2 text-main dark:text-dark-main text-center'>
                                {title}
                            </span>
                            {description && (
                                <span className='paragraph-3 text-additional dark:text-dark-additional text-center'>
                                    {description}
                                </span>
                            )}
                        </div>
                    </div>
                }
                footer={
                    <Button block className='h-10 xl:h-12' onClick={handleClose}>
                        {i18n?.t("common:ok_understood")}
                    </Button>
                }
                modalHeaderClassName='!px-4 !pt-4 xl:!px-6 xl:!pt-6'
                modalFooterClassName='!p-4 xl:!p-6'
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...options}
            />
        </EmotionThemeProvider>,
        elModal
    )
}

Modal.success = (text, options) => Modal.show(text, "Success", options)
Modal.error = (text, options) => Modal.show(text, "Failed", options)
Modal.warning = (text, options) => Modal.show(text, "Warning", options)

Modal.defaultProps = {
    open: false,
    onClose: () => {},
    header: undefined,
    footer: undefined,
    withCloseButton: false,
    disableBackdropClick: false,
    width: defaultWidth,
    height: undefined,
    minHeight: undefined,
    maxHeight: undefined,
    modalDialogClassName: undefined,
    modalHeaderClassName: undefined,
    modalBodyClassName: undefined,
    modalFooterClassName: undefined,
    fullScreen: false,
    order: undefined
}

export default Modal
