import { createAsyncThunk } from "@reduxjs/toolkit"
import { baseFetchApiV3 } from "@api/baseApi"

export const fetchBankSources = createAsyncThunk<BankTransferAPI[]>("bankSources/fetchBankSources", async () => {
    const response = await baseFetchApiV3({
        url: "/fiat/payment/bank/sources",
        method: "GET"
    })

    return response
})

export const checkPaymentRecomendation = createAsyncThunk<PaymentRecomendationAPI, PaymentRecomendationPayload>(
    "bankSources/checkPaymentRecomendation",
    async ({ amount, source }) => {
        const response = await baseFetchApiV3<PaymentRecomendationAPI>({
            url: "/fiat/payment/bank/recommendations",
            method: "POST",
            data: {
                amount: Number(amount),
                source
            },
            headers: {
                "Content-Type": "application/json"
            }
        })

        return response
    }
)
