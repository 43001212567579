import { createSlice } from "@reduxjs/toolkit"

const forgetPasswordSlice = createSlice({
    name: "forgetPassword",
    initialState: {
        isLoading: false
    },
    reducers: {}
})

export default forgetPasswordSlice
