/* eslint-disable jsx-a11y/anchor-is-valid */
import NextLink from "next/link"
import { useRouter } from "next/router"
import { UrlObject } from "url"

import styled from "@emotion/styled"
import { getQueryStringParam, setQueryStringParam } from "@utils/url"

const StyledLink = styled.a`
    width: initial;
    height: initial;
    display: inherit;

    text-decoration: none;
`

interface LinkProps extends React.PropsWithChildren<{}> {
    href: string | UrlObject
    target?: "_blank"
    locale?: string
    className?: string
    onClick?: () => void
    external?: boolean
}

const Link: React.FC<LinkProps> = ({ href, target, locale, children, className, onClick, external }: LinkProps) => {
    const router = useRouter()
    const shallow = Boolean(locale && router.locale === locale)

    const viewParam = getQueryStringParam("view", router.asPath)
    let newURL = href

    if (viewParam) {
        if (typeof newURL === "string") {
            newURL = setQueryStringParam("view", viewParam, newURL)
        } else {
            newURL.pathname = setQueryStringParam("view", viewParam, newURL.pathname || "")
        }
    }

    if (external) {
        if (typeof newURL === "string") {
            return (
                <StyledLink href={newURL} onClick={onClick} className={className} target={target}>
                    {children}
                </StyledLink>
            )
        }

        return (
            <StyledLink href={String(newURL.href)} onClick={onClick} className={className} target={target}>
                {children}
            </StyledLink>
        )
    }

    return (
        <NextLink href={newURL} locale={locale} passHref shallow={shallow} scroll={false}>
            <StyledLink onClick={onClick} className={className} target={target}>
                {children}
            </StyledLink>
        </NextLink>
    )
}

Link.defaultProps = {
    locale: undefined,
    target: undefined,
    className: undefined,
    onClick: undefined,
    external: false
}

export default Link
