/* eslint-disable no-restricted-globals */
import { SKIP_REGISTRATION_FLOW } from "@config/leaderboard"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CompetitionsModel } from "@type/model/competitions.d"

import { fetchCompetition } from "./actions"

const competitionSlicer = createSlice({
    name: "competition",
    initialState: {
        competition: null as CompetitionsModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchCompetition.pending.type]: (state) => {
            state.isLoading = true
            state.competition = null
        },
        [fetchCompetition.fulfilled.type]: (state, action: PayloadAction<CompetitionsApi>) => {
            state.competition = {
                status: action.payload.status,
                rank: action.payload.rank,
                score: action.payload.score,
                config: {
                    id: action.payload.config.id,
                    title: action.payload.config.title,
                    tnc: {
                        howToParticipate: action.payload.config.tnc.how_to_participate,
                        competitionRules: action.payload.config.tnc.competition_rules
                    },
                    poolPrize: action.payload.config.pool_prize,
                    competitionStartTime: action.payload.config.competition_start_time,
                    competitionEndTime: action.payload.config.competition_end_time,
                    reviewStartTime: action.payload.config.review_start_time,
                    reviewEndTime: action.payload.config.review_end_time,
                    releaseStartTime: action.payload.config.release_start_time,
                    releaseEndTime: action.payload.config.release_end_time,
                    status: action.payload.config.status
                },
                skipRegistration: SKIP_REGISTRATION_FLOW
            }
            state.isLoading = false
        }
    }
})

export default competitionSlicer
