import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const validationAddress = createAsyncThunk<ValidationAddressApi, AddressValidationPayload>(
    "transactions/validationAddress",
    async (payload) => {
        const response = await baseFetchApiV3<ValidationAddressApi>({
            url: "/wallet/fiat/address/validation",
            method: "POST",
            data: payload
        })

        return response
    }
)
