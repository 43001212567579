import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchMarketTransactions, fetchMarketTransactionsAsync, FetchMarketTransactionsResponse } from "./actions"

const marketTransactionsSlicer = createSlice({
    name: "marketTransactions",
    initialState: {
        marketTransactions: null as MarketTransactionModel[] | null,
        coinCode: null as string | null,
        isLoading: false
    },
    reducers: {
        addMarketTransactions: (state, action: PayloadAction<TradeSocketData>) => {
            action.payload.t.forEach((item) => {
                if (state.marketTransactions) {
                    if (action.payload.c !== state.coinCode) return

                    const checkExist = state.marketTransactions.find(
                        (marketTransaction) => marketTransaction.id === item[5]
                    )

                    if (checkExist) return

                    state.marketTransactions.unshift({
                        id: item[5],
                        timestamp: item[0],
                        transactionType: item[1] ? "sell" : "buy",
                        price: item[2],
                        idr: item[3],
                        coin: item[4]
                    })

                    state.marketTransactions = state.marketTransactions.slice(0, 25)
                }
            })
        }
    },
    extraReducers: {
        [fetchMarketTransactions.pending.type]: (state) => {
            state.isLoading = true
            state.marketTransactions = null
            state.coinCode = null
        },
        [fetchMarketTransactions.fulfilled.type]: (state, action: PayloadAction<FetchMarketTransactionsResponse>) => {
            state.marketTransactions = action.payload.data.map((item) => ({
                id: item[5],
                timestamp: item[0],
                transactionType: item[1] ? "sell" : "buy",
                price: item[2],
                idr: item[3],
                coin: item[4]
            }))

            state.coinCode = action.payload.coinCode
            state.isLoading = false
        },
        [fetchMarketTransactionsAsync.fulfilled.type]: (
            state,
            action: PayloadAction<FetchMarketTransactionsResponse>
        ) => {
            state.marketTransactions = action.payload.data.map((item) => ({
                id: item[5],
                timestamp: item[0],
                transactionType: item[1] ? "sell" : "buy",
                price: item[2],
                idr: item[3],
                coin: item[4]
            }))

            state.coinCode = action.payload.coinCode
            state.isLoading = false
        }
    }
})

export const { addMarketTransactions } = marketTransactionsSlicer.actions
export default marketTransactionsSlicer
