import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"
import { getAuth } from "@helpers/auth"

export interface FetchWalletUserResponse extends WalletUserAPI {
    userId: number
}

export const fetchWalletUser = createAsyncThunk<WalletUserAPI>(
    "walletUser/fetchWalletUser",
    async (_, { getState }) => {
        const auth = getAuth()
        const { userId } = (getState() as any).profile.profile

        const response = await fetchApi<WalletUserAPI>({
            url: "/walletdetail",
            method: "POST",
            data: {
                id: "IDR",
                token: auth.token
            }
        })

        return { ...response, userId }
    }
)
