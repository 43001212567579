import { createAsyncThunk } from "@reduxjs/toolkit"
import baseFetchApi from "@api/baseApi"

export const simpleOrderAction = createAsyncThunk<SimpleOrderResultAPI, SimpleOrderPayload>(
    "simple/order",
    async (payload) => {
        const response = await baseFetchApi<SimpleOrderResultAPI>({
            url: "/otc/order",
            method: "POST",
            data: payload
        })

        return response
    }
)
