/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchApiKeys } from "./actions"

const apiKeysSlicer = createSlice({
    name: "apiKeys",
    initialState: {
        apiKeys: null as APIKeyModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchApiKeys.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchApiKeys.fulfilled.type]: (state, action: PayloadAction<APIKeyAPI[]>) => {
            state.apiKeys = action.payload
            state.isLoading = false
        }
    }
})

export default apiKeysSlicer
