/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchBuySellVolume = createAsyncThunk(
    "guidance/fetchBuySellVolume",
    async ({ product_id }: BuySellVolumePayload) => {
        const response = await fetchApi({
            url: "/functions/guidance",
            method: "GET",
            params: {
                account_id: product_id
            }
        })

        return response
    }
)
