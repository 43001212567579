import { baseFetchApiV3 } from "@api/baseApi"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchPhoneNumber = createAsyncThunk<PhoneNumberApi>("phonenumber/fetchPhoneNumber", async () => {
    const phoneNumber = await baseFetchApiV3<BaseResponseAPIV3<PhoneNumberApi>>({
        url: "/user/phone-numbers",
        method: "GET"
    })

    return phoneNumber.data
})
