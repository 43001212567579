/* eslint-disable import/prefer-default-export */
export const getInitialName = (fullName: string): string => {
    let initial: string | string[] = ""

    if (fullName) {
        initial = fullName.split(" ")

        if (initial.length > 1) {
            initial = initial[0].charAt(0).toUpperCase() + initial[1].charAt(0).toUpperCase()
        } else {
            initial = fullName.charAt(0).toUpperCase() + fullName.charAt(1).toUpperCase()
        }
    }

    return initial
}
