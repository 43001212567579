import dynamic from "next/dynamic"

const FeedbackIllustration = {
    Failed: dynamic(() => import("./Failed")),
    InProcess: dynamic(() => import("./InProcess")),
    Success: dynamic(() => import("./Success")),
    Verified: dynamic(() => import("./Verified")),
    Warning: dynamic(() => import("./Warning"))
}

export default FeedbackIllustration
