import React, { useImperativeHandle, useRef, useState } from "react"
import ReactCustomScroll from "react-custom-scroll"
import tw from "twin.macro"

import { css, Global } from "@emotion/react"

export interface CustomScrollRef {
    scrollTo: (top: number) => void
    scrollToId: (id: string) => void
    getHasScroll: () => boolean
}

interface Props extends React.PropsWithChildren<{}> {
    flex?: number
}

const CustomScroll = React.forwardRef<CustomScrollRef, Props>(({ children, flex }, ref) => {
    const customScrollRef = useRef<any>()
    const [scrollTo, setScrollTo] = useState<number>()

    useImperativeHandle(ref, () => ({
        scrollTo: (top: number) => {
            setScrollTo(top)
        },
        scrollToId: (id: string) => {
            const el = document.querySelector(`#${id}`) as HTMLElement | null

            if (el) {
                const top = el.offsetTop
                setScrollTo(top - 10)
            }
        },
        getHasScroll: () => customScrollRef.current.hasScroll
    }))

    const handleScroll = () => {
        setScrollTo(undefined)
    }

    return (
        <>
            <Global
                styles={css`
                    .rcs-custom-scroll {
                        ${tw`w-full`}

                        min-height: 0;
                        min-width: 0;
                    }
                    .rcs-custom-scroll .rcs-outer-container {
                        overflow: hidden;
                    }
                    .rcs-custom-scroll .rcs-outer-container .rcs-positioning {
                        position: relative;
                    }
                    .rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
                        opacity: 1;
                        transition-duration: 0.2s;
                    }
                    .rcs-custom-scroll .rcs-inner-container {
                        overflow-x: hidden;
                        overflow-y: scroll;
                        -webkit-overflow-scrolling: touch;
                    }
                    .rcs-custom-scroll .rcs-inner-container:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        height: 0;
                        background-image: linear-gradient(
                            to bottom,
                            rgba(0, 0, 0, 0.2) 0%,
                            rgba(0, 0, 0, 0.05) 60%,
                            rgba(0, 0, 0, 0) 100%
                        );
                        pointer-events: none;
                        transition: height 0.1s ease-in;
                        will-change: height;
                    }
                    .rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
                        height: 5px;
                        transition: height 0.15s ease-out;
                    }
                    .rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
                        user-select: none;
                    }
                    .rcs-custom-scroll .rcs-custom-scrollbar {
                        position: absolute;
                        height: 100%;
                        ${tw`w-2`}
                        ${tw`right-0`}
                        opacity: 0;
                        z-index: 1;
                        transition: opacity 0.4s ease-out;
                        ${tw`py-2 px-0`}
                        box-sizing: border-box;
                        will-change: opacity;
                        pointer-events: none;
                    }
                    .rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
                        right: auto;
                        left: 3px;
                    }
                    .rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
                        opacity: 1;
                    }
                    .rcs-custom-scroll .rcs-custom-scroll-handle {
                        position: absolute;
                        width: 100%;
                        top: 0;
                    }
                    .rcs-custom-scroll .rcs-inner-handle {
                        ${tw`h-[calc(100%_-_24px)]`}
                        ${tw`mt-3`}

                        ${tw`bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-300 dark:bg-background-dark-600`}
                        ${tw`rounded-full`}
                    }
                `}
            />
            <ReactCustomScroll
                scrollTo={scrollTo}
                onScroll={handleScroll}
                allowOuterScroll
                flex={flex}
                ref={customScrollRef}
            >
                {children}
            </ReactCustomScroll>
        </>
    )
})

CustomScroll.defaultProps = {
    flex: undefined
}

export default CustomScroll
