import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchCoinNetwork } from "./actions"

const coinNetworkSlicer = createSlice({
    name: "coinNetwork",
    initialState: {
        coinNetwork: null as CoinNetworkModel | null,
        coinCode: null as string | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchCoinNetwork.pending.type]: (state) => {
            state.isLoading = true
            state.coinNetwork = null
            state.coinCode = null
        },
        [fetchCoinNetwork.fulfilled.type]: (state, action: PayloadAction<CoinNetworkAPI>) => {
            state.coinNetwork = {
                accountId: action.payload.account_id,
                accountName: action.payload.account_name,
                accountCode: action.payload.account_code,
                chains: action.payload.chains.map((chain) => ({
                    id: chain.id,
                    chainName: chain.chain_name,
                    chainCode: chain.chain_code,
                    status: chain.status,
                    statusWithdraw: chain.status_withdraw,
                    statusDeposit: chain.status_deposit,
                    addressRegex: chain.address_regex,
                    memoRegex: chain.memo_regex,
                    withdrawFee: chain.withdraw_fee,
                    depositNote: chain.deposit_note,
                    withdrawNote: chain.withdraw_note
                }))
            }

            state.coinCode = action.payload.account_code
            state.isLoading = false
        }
    }
})

export default coinNetworkSlicer
