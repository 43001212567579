/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { baseFetchApiV3 } from "@api/baseApi"

export const fetchBalancesMemberAction = createAsyncThunk<BalancesApi, { hash: string }>(
    "balances/fetchBalancesMember",
    async ({ hash }) => {
        const response = await baseFetchApiV3({
            url: `/balance/${hash}`,
            method: "GET"
        })

        return response
    }
)
