import { createAsyncThunk } from "@reduxjs/toolkit"

import fetchApi from "@api/baseApi"
import { TransactionType } from "@type/model/wallet-history.d"

export interface WalletHistoryFilter {
    month?: number
    year?: number
    offset?: number
    transactionType?: TransactionType
}

export const fetchWalletHistory = createAsyncThunk<WalletHistoryAPI, WalletHistoryFilter>(
    "WalletHistory/fetchWalletHistory",
    async ({ month = new Date().getMonth() + 1, year = new Date().getFullYear(), offset = 0, transactionType }) => {
        let data: {} = {
            bulan: month,
            tahun: year,
            o: offset
        }

        if (typeof transactionType !== "undefined") {
            data = {
                ...data,
                tt: transactionType
            }
        }

        const response = await fetchApi({
            url: "/historydepowd",
            method: "POST",
            data
        })

        return response
    }
)
