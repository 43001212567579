import { css, Theme } from "@emotion/react"
import tw from "twin.macro"

const globalStyles = (theme: Theme) => css`
    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        overscroll-behavior-y: none;
        background-color: ${theme.colors.backgroundColor};
        color: ${theme.colors.text.base};

        ${tw`bg-white dark:bg-background-dark-900`}
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`

export default globalStyles
