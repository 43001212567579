import { styled } from "twin.macro"

export interface ContainerProps {
    className?: string
}

const Container: React.FC<ContainerProps> = styled.div`
    width: 90%;
    min-width: 1210px;
    max-width: 1400px;

    box-sizing: border-box;
    margin: 0px auto;
    padding: 0px 20px;

    @media screen and (max-width: 1280px) {
        min-width: -webkit-fill-available;
    }
`

export default Container
