/* eslint-disable import/prefer-default-export */
import { APP_ENV } from "@config/config"
import { isBrowser } from "@utils/browser"
import { getCookie, removeCookie, setCookie } from "./cookies"

const isDevelopment = APP_ENV?.toLocaleLowerCase() === "development"
const hashKey = isDevelopment ? "devuhash" : "uhash"
const tokenKey = isDevelopment ? "devrkhash" : "rkhash"

export const getAuth = (): AuthModel => {
    const uhash = getCookie(hashKey)
    const rkhash = getCookie(tokenKey)

    const checkAuth = Boolean(uhash && rkhash)

    return {
        token: rkhash,
        hash: uhash,
        isLoggedIn: checkAuth
    }
}

export const setAuth = ({ hash, token }: AuthAPI) => {
    if (hash) setCookie(hashKey, hash)
    if (token) setCookie(tokenKey, token)

    return true
}

if (isBrowser && isDevelopment) {
    window.setAuth = setAuth
}

export const resetAuth = () => {
    removeCookie(hashKey)
    removeCookie(tokenKey)

    return true
}
