const migrations = {
    2: (state: any) => ({
        ...state,
        userPreferences: {
            ...state.userPreferences,
            rehydrated: false
        }
    })
}

export default migrations
