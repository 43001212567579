import { createAsyncThunk } from "@reduxjs/toolkit"
import baseFetchApi from "@api/baseApi"

export interface FetchChartArgs {
    coinId: number
    period: number
}

export const fetchChart = createAsyncThunk<ChartAPI, FetchChartArgs>("chart/fetchChart", async ({ period, coinId }) => {
    const response = await baseFetchApi({
        url: "/chart",
        params: {
            t: 2,
            f: period,
            id: coinId
        }
    })

    return response
})
