import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchOurBanks, fetchOurBanksVIP } from "./actions"

const ourBanksSlicer = createSlice({
    name: "ourBanks",
    initialState: {
        ourBanks: null as VaBankModel | null,
        ourBanksVIP: null as OurBankModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchOurBanks.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchOurBanks.fulfilled.type]: (state, action: PayloadAction<VaBankAPI>) => {
            state.ourBanks = {
                bank: action.payload.bank,
                fee: action.payload.fee,
                howto: {
                    atm: action.payload.howto.atm,
                    ibanking: action.payload.howto.ibanking,
                    mbanking: action.payload.howto.mbanking,
                    smsbanking: action.payload.howto.smsbanking
                },
                name: action.payload.vaname,
                vano: action.payload.vano
            }
            state.isLoading = false
        },
        [fetchOurBanksVIP.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchOurBanksVIP.fulfilled.type]: (state, action: PayloadAction<OurBankAPI[]>) => {
            state.ourBanksVIP = action.payload.map((ourbank) => ({
                id: ourbank.id,
                bankId: ourbank.bankid,
                name: ourbank.bankname,
                branch: ourbank.bankbranch,
                accountName: ourbank.bankaccountname,
                accountNumber: ourbank.bankaccountnumber,
                logo: ourbank.payment_logo
            }))
            state.isLoading = false
        }
    }
})

export default ourBanksSlicer
