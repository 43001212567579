import { createSlice } from "@reduxjs/toolkit"
import { sendLostAuth } from "./actions"

const lostAuthSlicer = createSlice({
    name: "lostAuth",
    initialState: {
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [sendLostAuth.pending.type]: (state) => {
            state.isLoading = true
        },
        [sendLostAuth.fulfilled.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default lostAuthSlicer
