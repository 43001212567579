import { createAsyncThunk } from "@reduxjs/toolkit"
import baseFetchApi from "@api/baseApi"

export const fetchPrices = createAsyncThunk<PricesAPI>("prices/fetchPrices", async () => {
    const response = await baseFetchApi({
        url: "/price",
        method: "GET"
    })

    return response
})
