import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchSummaryChartAction } from "./actions"

export const emptySummaryDetail = {
    timestamp: 0,
    amount: 0,
    percentage: 0,
    all: 0
}

const portfolioSummaryChartSlice = createSlice({
    name: "summaryChart",
    initialState: {
        summaryChart: null as PortfolioSummaryChartModel | null,
        summaryDetail: null as PortfolioSummaryChartData | null,
        isLoading: true
    },
    reducers: {
        setHoverData: (state, action: PayloadAction<PortfolioSummaryChartData | null>) => {
            state.summaryDetail = action.payload
        }
    },
    extraReducers: {
        [fetchSummaryChartAction.pending.type]: (state) => {
            state.summaryChart = null
            state.summaryDetail = null
            state.isLoading = true
        },
        [fetchSummaryChartAction.fulfilled.type]: (state, action: PayloadAction<PortfolioSummaryChartAPI>) => {
            state.summaryChart = action.payload.map((chart) => ({
                timestamp: chart.t,
                percentage: chart.p,
                amount: chart.a,
                all: chart.all
            }))

            state.isLoading = false
        }
    }
})

export default portfolioSummaryChartSlice
export const { setHoverData } = portfolioSummaryChartSlice.actions
