import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchFeeWd } from "./actions"

const feeWdSlicer = createSlice({
    name: "feeWd",
    initialState: {
        feeWd: null as FeeWdModel | null,
        isLoading: false
    },
    reducers: {
        clearFeeData: (state) => {
            state.feeWd = null
            state.isLoading = false
        }
    },
    extraReducers: {
        [fetchFeeWd.pending.type]: (state) => {
            state.isLoading = true
            state.feeWd = null
        },
        [fetchFeeWd.fulfilled.type]: (state, action: PayloadAction<FeeWdAPI>) => {
            state.feeWd = {
                fee: action.payload.fee,
                idrFee: action.payload.idr_fee
            }
            state.isLoading = false
        }
    }
})

export const { clearFeeData } = feeWdSlicer.actions
export default feeWdSlicer
