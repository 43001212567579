import { useAppDispatch } from "@redux/hooks"
import { LangArgs, updateLang as updateLangAction } from "@redux/lang/actions"
import useAuth from "./useAuth"

const useLang = () => {
    const dispatch = useAppDispatch()
    const { auth } = useAuth()

    const updateLang = async ({ lang }: LangArgs) => {
        if (auth.isLoggedIn) {
            await dispatch(updateLangAction({ lang })).unwrap()
        }
    }

    return { updateLang }
}

export default useLang
