import baseFetchApi from "@api/baseApi"
import { getAuth } from "@helpers/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchFeeWd = createAsyncThunk<FeeWdAPI, FeeWdPayload>("feeWd/fetchFeeWd", async (payload) => {
    const auth = getAuth()
    const response = await baseFetchApi({
        url: "/feewd",
        method: "POST",
        data: {
            ...payload,
            token: auth.token
        }
    })

    return response
})
