import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"
import { getAuth } from "@helpers/auth"

export interface OurBanksArgs {
    id: number
    type: number
}

export const fetchOurBanksVIP = createAsyncThunk<OurBankAPI[]>("banks/fetchOurBanksVIP", async () => {
    const auth = getAuth()

    const response = await fetchApi({
        url: "/ourbanks",
        method: "POST",
        data: {
            token: auth.token
        }
    })

    return response
})

export const fetchOurBanks = createAsyncThunk<VaBankAPI, OurBanksArgs>("banks/fetchOurBanks", async ({ id, type }) => {
    const auth = getAuth()

    const response = await fetchApi<VaBankAPI>({
        url: "/ourbanks",
        method: "POST",
        data: {
            i: id,
            t: type,
            token: auth.token
        }
    })

    return response
})
