/* eslint-disable import/prefer-default-export */
import dynamic from "next/dynamic"

const IconComponents = {
    Add: dynamic(() => import("./Add")),
    AddCircle: dynamic(() => import("./AddCircle")),
    AddContact: dynamic(() => import("./AddContact")),
    Analysis: dynamic(() => import("./Analysis")),
    ArrowDown: dynamic(() => import("./ArrowDown")),
    ArrowLeft: dynamic(() => import("./ArrowLeft")),
    ArrowRight: dynamic(() => import("./ArrowRight")),
    Blink: dynamic(() => import("./Blink")),
    Calendar: dynamic(() => import("./Calendar")),
    Campus: dynamic(() => import("./Campus")),
    Card: dynamic(() => import("./Card")),
    CaretDown: dynamic(() => import("./CaretDown")),
    Check: dynamic(() => import("./Check")),
    CheckCircle: dynamic(() => import("./CheckCircle")),
    CheckCircleFilled: dynamic(() => import("./CheckCircleFilled")),
    Checked: dynamic(() => import("./Checked")),
    ChevronDown: dynamic(() => import("./ChevronDown")),
    ChevronLeft: dynamic(() => import("./ChevronLeft")),
    ChevronRight: dynamic(() => import("./ChevronRight")),
    ChevronRightNew: dynamic(() => import("./ChevronRightNew")),
    ChevronUp: dynamic(() => import("./ChevronUp")),
    Clock: dynamic(() => import("./Clock")),
    Coin: dynamic(() => import("./Coin")),
    CoinStaked: dynamic(() => import("./CoinStaked")),
    Contact: dynamic(() => import("./Contact")),
    Copy: dynamic(() => import("./Copy")),
    Copy2Bold: dynamic(() => import("./Copy2Bold")),
    Coupon: dynamic(() => import("./Coupon")),
    Deposit: dynamic(() => import("./Deposit")),
    Download: dynamic(() => import("./Download")),
    EditBold: dynamic(() => import("./EditBold")),
    Ewallet: dynamic(() => import("./Ewallet")),
    Exclamation: dynamic(() => import("./Exclamation")),
    GuideMe: dynamic(() => import("./GuideMe")),
    HeartFilled: dynamic(() => import("./HeartFilled")),
    HeartOutlined: dynamic(() => import("./HeartOutlined")),
    Home: dynamic(() => import("./Home")),
    Hourglass: dynamic(() => import("./Hourglass")),
    Idr: dynamic(() => import("./Idr")),
    InfoCircle: dynamic(() => import("./InfoCircle")),
    LightShape: dynamic(() => import("./LightShape")),
    LightningModeActive: dynamic(() => import("./LightningModeActive")),
    LightningModeInactive: dynamic(() => import("./LightningModeInactive")),
    Livechat: dynamic(() => import("./Livechat")),
    LogoIdr: dynamic(() => import("./LogoIdr")),
    LogoIdrNew: dynamic(() => import("./LogoIdrNew")),
    Market: dynamic(() => import("./Market")),
    Menu: dynamic(() => import("./Menu")),
    Moon: dynamic(() => import("./Moon")),
    NoPhoneNumber: dynamic(() => import("./NoPhoneNumber")),
    NoPhoneNumberDark: dynamic(() => import("./NoPhoneNumberDark")),
    NoTransaction: dynamic(() => import("./NoTransaction")),
    PlusCircle: dynamic(() => import("./PlusCircle")),
    PriceDown: dynamic(() => import("./PriceDown")),
    PriceUp: dynamic(() => import("./PriceUp")),
    ProShape: dynamic(() => import("./ProShape")),
    ProModeActive: dynamic(() => import("./ProModeActive")),
    ProModeInactive: dynamic(() => import("./ProModeInactive")),
    QrCode: dynamic(() => import("./QrCode")),
    QrCodeScanner: dynamic(() => import("./QrCodeScanner")),
    Recap: dynamic(() => import("./Recap")),
    Referral: dynamic(() => import("./Referral")),
    Remove: dynamic(() => import("./Remove")),
    Search: dynamic(() => import("./Search")),
    Security: dynamic(() => import("./Security")),
    Setting: dynamic(() => import("./Setting")),
    Staked: dynamic(() => import("./Staked")),
    Staking: dynamic(() => import("./Staking")),
    Sun: dynamic(() => import("./Sun")),
    SwitchVertical: dynamic(() => import("./SwitchVertical")),
    Toa: dynamic(() => import("./Toa")),
    TotalAsset: dynamic(() => import("./TotalAsset")),
    Trade: dynamic(() => import("./Trade")),
    UnChecked: dynamic(() => import("./UnChecked")),
    Upload: dynamic(() => import("./Upload")),
    User: dynamic(() => import("./User")),
    VerifyAccount: dynamic(() => import("./VerifyAccount")),
    Wallet: dynamic(() => import("./Wallet")),
    WarningCircle: dynamic(() => import("./WarningCircle")),
    Wifi: dynamic(() => import("./Wifi")),
    Withdraw: dynamic(() => import("./Withdraw")),
    World: dynamic(() => import("./World")),
    X: dynamic(() => import("./X")),
    XCircleFilled: dynamic(() => import("./XCircleFilled"))
}

export default IconComponents
