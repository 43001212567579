import { defaultLanguage } from "@config/i18n"
import { isBrowser } from "@utils/browser"
import localStorage from "@utils/localStorage"
import { getCookie, setCookie } from "./cookies"

/* eslint-disable import/prefer-default-export */
const key = "lang"

export const getLocale = () => {
    const locale = isBrowser ? getCookie(key) : defaultLanguage
    return locale
}

export const setLocale = async (locale: string) => {
    setCookie(key, locale)

    const promises: Promise<any>[] = [import("dayjs")]

    switch (locale) {
        case "id":
            promises.push(import(`dayjs/locale/id`))
            break
        case "en":
            promises.push(import(`dayjs/locale/en`))
            break
        default:
            break
    }

    const [{ default: dayjs }] = await Promise.all(promises)
    dayjs.locale(locale)

    return true
}

export const resetAuth = () => {
    localStorage.removeItem(key)
    return true
}
