import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchCoinNetwork = createAsyncThunk<CoinNetworkAPI, string>(
    "coinNetwork/fetchCoinNetwork",
    async (coinCode) => {
        const response = await baseFetchApi({
            url: `/wallet/${coinCode}`
        })

        return response
    }
)
