import { getAuth } from "@helpers/auth"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchCancelCoin } from "./actions"

const auth = getAuth()
const cancelSendCoinSlicer = createSlice({
    name: "cancelSendCoin",
    initialState: {
        hash: auth.hash || null,
        token: auth.token || null,
        isLoading: false
    } as unknown as CancelSendCoinModel,
    reducers: {},
    extraReducers: {
        [fetchCancelCoin.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCancelCoin.fulfilled.type]: (state, action: PayloadAction<CancelSendCoinModel>) => {
            state.hash = action.payload?.hash
            state.token = action.payload?.token
            state.isLoading = false
        },
        [fetchCancelCoin.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default cancelSendCoinSlicer
