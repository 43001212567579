import { ThemeCommon } from "@emotion/react"

const themeCommon: ThemeCommon = {
    fontSize: {
        title: {
            1: 40,
            2: 32,
            3: 28,
            4: 24,
            5: 20,
            6: 16
        },
        body: 14
    },
    fontFamily: "'Manrope', sans-serif",
    spacing: {
        small: 8,
        middle: 16,
        large: 32
    }
}

export default themeCommon
