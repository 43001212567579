import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchPlBreakdown = createAsyncThunk<PlBreakdownAPI>("plBreakdown/fetchPlBreakdown", async () => {
    const response = await baseFetchApiV3({
        url: "/pnl/summary/cumulative/breakdown",
        method: "GET"
    })

    return response.data
})
