import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { fetchPersonalityTestQuestion } from "./actions"

const personalityTestQuestionSlicer = createSlice({
    name: "personalityTestQuestion",
    initialState: {
        data: null as PersonalityTestQuestionModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchPersonalityTestQuestion.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchPersonalityTestQuestion.fulfilled.type]: (state, action: PayloadAction<PersonalityTestQuestionAPI>) => {
            state.data = action.payload.map((question) => ({
                id: question.id,
                isRequired: question.is_required,
                question: question.question,
                options: question.options.map((option) => ({
                    id: option.id,
                    text: option.text
                }))
            }))

            state.isLoading = false
        }
    }
})

export default personalityTestQuestionSlicer
