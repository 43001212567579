import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchStatusAuth } from "./action"

const statusAuthSlicer = createSlice({
    name: "statusAuth",
    initialState: {
        statusAuth: null as StatusAuthModel | null,
        isLoading: true
    },
    reducers: {
        setTab: (state, action: PayloadAction<StatusAuthModel>) => {
            state.statusAuth = {
                ...state.statusAuth,
                tab: action.payload.tab
            }
        }
    },
    extraReducers: {
        [fetchStatusAuth.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchStatusAuth.fulfilled.type]: (state, action: PayloadAction<StatusAuthApi>) => {
            state.isLoading = false
            state.statusAuth = {
                authtype: action.payload.authtype,
                phone: action.payload.phone_number,
                tab: action.payload.tab
            }
        }
    }
})

export const { setTab } = statusAuthSlicer.actions

export default statusAuthSlicer
