import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import BigNumber from "bignumber.js"

import { getOrderType } from "@utils/order-type"
import { fetchOrderDetail } from "./actions"

const orderDetailSlicer = createSlice({
    name: "orderDetail",
    initialState: {
        orderDetail: null as OrderDetailModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchOrderDetail.pending.type]: (state) => {
            state.isLoading = true
            state.orderDetail = null
        },
        [fetchOrderDetail.fulfilled.type]: (state, action: PayloadAction<OrderDetailAPI[]>) => {
            state.orderDetail = action.payload.map(({ fee, ...item }) => {
                const vatCoin = item.vat
                const originalFee = new BigNumber(fee).minus(vatCoin).toNumber()

                return {
                    id: item.id,
                    datetime: item.dt,
                    coinCode: item.c,
                    amount: item.oa,
                    price: item.a,
                    total: item.t,
                    orderType: getOrderType(item.tt),
                    taker: item.taker,
                    fee: {
                        original_fee: originalFee,
                        vat: {
                            idr: item.vatrp,
                            coin: vatCoin
                        }
                    }
                }
            })

            state.isLoading = false
        }
    }
})

export default orderDetailSlicer
