/* eslint-disable import/prefer-default-export */
import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { UserMode } from "@type/api/user-mode.d"

export interface FetchMarketsResponse extends FetchMarketsArgs {
    markets: MarketApi[]
}

export interface FetchMarketsArgs {
    mode: UserMode
    enable?: boolean
    delisted?: boolean
}

const handleFetchMarkets = async ({ mode, enable = false, delisted = false }: FetchMarketsArgs) => {
    const marketMode = mode === UserMode.Pro ? "trade" : "otc"

    const response = await baseFetchApiV3({
        url: "/market",
        method: "GET",
        params: {
            mode: marketMode,
            enable: Number(enable),
            delisted: Number(delisted)
        }
    })

    return { markets: response, mode, enable, delisted }
}

export const fetchMarkets = createAsyncThunk<FetchMarketsResponse, FetchMarketsArgs>(
    "markets/fetchMarkets",
    handleFetchMarkets
)

export const fetchMarketsAsync = createAsyncThunk<FetchMarketsResponse, FetchMarketsArgs>(
    "markets/fetchMarketsAsync",
    handleFetchMarkets
)
