import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const tradePreferences = createSlice({
    name: "tradePreferences",
    initialState: {
        userOrderTab: "pending"
    },
    reducers: {
        changeTradePreference: (state, action: PayloadAction<TradePreferencesModel>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeTradePreference } = tradePreferences.actions
export default tradePreferences
