/* eslint-disable import/prefer-default-export */
import { ColorPallete } from "@emotion/react"

export const blue: ColorPallete = {
    100: "var(--colors-semantic-primary-100)",
    200: "var(--colors-semantic-primary-200)",
    300: "var(--colors-semantic-primary-300)",
    400: "var(--colors-semantic-primary-400)",
    500: "var(--colors-semantic-primary-500)",
    600: "var(--colors-semantic-primary-600)",
    700: "var(--colors-semantic-primary-700)",
    800: "var(--colors-semantic-primary-800)",
    900: "var(--colors-semantic-primary-900)"
}

export const secondary: ColorPallete = {
    10: "var(--colors-semantic-secondary-10)",
    100: "var(--colors-semantic-secondary-100)",
    200: "var(--colors-semantic-secondary-200)",
    300: "var(--colors-semantic-secondary-300)",
    400: "var(--colors-semantic-secondary-400)",
    500: "var(--colors-semantic-secondary-500)",
    600: "var(--colors-semantic-secondary-600)",
    700: "var(--colors-semantic-secondary-700)",
    800: "var(--colors-semantic-secondary-800)",
    900: "var(--colors-semantic-secondary-900)"
}

export const green: ColorPallete = {
    100: "var(--colors-semantic-success-100)",
    200: "var(--colors-semantic-success-200)",
    300: "var(--colors-semantic-success-300)",
    400: "var(--colors-semantic-success-400)",
    500: "var(--colors-semantic-success-500)",
    600: "var(--colors-semantic-success-600)",
    700: "var(--colors-semantic-success-700)",
    800: "var(--colors-semantic-success-800)",
    900: "var(--colors-semantic-success-900)"
}

export const yellow: ColorPallete = {
    100: "var(--colors-semantic-warning-100)",
    200: "var(--colors-semantic-warning-200)",
    300: "var(--colors-semantic-warning-300)",
    400: "var(--colors-semantic-warning-400)",
    500: "var(--colors-semantic-warning-500)",
    600: "var(--colors-semantic-warning-600)",
    700: "var(--colors-semantic-warning-700)",
    800: "var(--colors-semantic-warning-800)",
    900: "var(--colors-semantic-warning-900)"
}

export const red: ColorPallete = {
    100: "var(--colors-semantic-danger-100)",
    200: "var(--colors-semantic-danger-200)",
    300: "var(--colors-semantic-danger-300)",
    400: "var(--colors-semantic-danger-400)",
    500: "var(--colors-semantic-danger-500)",
    600: "var(--colors-semantic-danger-600)",
    700: "var(--colors-semantic-danger-700)",
    800: "var(--colors-semantic-danger-800)",
    900: "var(--colors-semantic-danger-900)"
}
