import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface FetchCompetitionLeaderboardParams {
    type: string
    page: number
    competitionId?: string
}

export const fetchCompetitionLeaderboard = createAsyncThunk<
    CompetitionLeaderboardAPI,
    FetchCompetitionLeaderboardParams
>("competitionLeaderboard/fetchCompetitionLeaderboard", async ({ type, page, competitionId = "latest" }) => {
    const response = await baseFetchApiV3<CompetitionLeaderboardAPI>({
        url: `/mission/competitions/${type}/leaderboards/${competitionId}?page=${page}`
    })

    return response
})
