import { baseFetchApiV3 } from "@api/baseApi"
import { getFirebaseDatabase } from "@config/firebase"
import { getAuth } from "@helpers/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const depositTransfer = createAsyncThunk("deposit/transfer", async (payload: DepoTransferPayload) => {
    const auth = getAuth()
    const response = await baseFetchApiV3<TransactionDepositAPI>({
        url: "/transactions",
        method: "POST",
        data: {
            ...payload,
            transtype: 0,
            token: auth.token
        }
    })

    return response
})
export const paymentGuide = createAsyncThunk("paymentGuide/paymentGuide", async () => {
    const { child, onValue, ref } = await import("firebase/database")

    const database = await getFirebaseDatabase()
    const rekuCampusRef = child(ref(database, "global"), "transfer_guide")
    const dataPromise = new Promise((resolve) => {
        onValue(rekuCampusRef, (snapshot) => {
            const data: PaymentGuideModel[] = []
            snapshot.forEach((item) => {
                const value = item.val()
                data.push({
                    ...value,
                    key: item.key
                })
            })

            resolve(data)
        })
    })

    return dataPromise
})
