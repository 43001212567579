import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchBankTransfer = createAsyncThunk<BankTransferAPI[]>("banks/fetchBankTransfer", async () => {
    const response = await fetchApi({
        url: "/banktransfer",
        method: "GET"
    })

    return response
})
