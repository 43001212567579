import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const internaltransfer = createAsyncThunk<SuccessAPI, TransactionInternalTfPayload>(
    "transactions/internaltransfer",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await baseFetchApiV3<SuccessAPI>({
                url: "/internaltransfer",
                method: "POST",
                data: payload
            })

            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
