import baseFetchApi from "@api/baseApi"

import { createAsyncThunk } from "@reduxjs/toolkit"

export interface ChangePasswordArgs {
    token: string
    otp?: string
    oldpwd: string
    pwd: string
}

export const fetchChangePassword = createAsyncThunk<SuccessV2API, ChangePasswordArgs>(
    "status/disableAuth",
    async ({ token, otp, oldpwd, pwd }, { rejectWithValue }) => {
        try {
            const formData = new FormData()

            formData.append("token", token)

            formData.append("oldpwd", oldpwd)
            formData.append("pwd", pwd)
            formData.append("otp", otp || "")

            const response = await baseFetchApi<SuccessV2API>({
                url: "/changepassword",
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            })

            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)
