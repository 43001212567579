/* eslint-disable no-restricted-globals */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchInvestorPersonalityProfile } from "./actions"

const investorPersonalityProfileSlicer = createSlice({
    name: "investorPersonalityProfile",
    initialState: {
        personalityProfile: null as PersonalityProfileModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchInvestorPersonalityProfile.pending.type]: (state) => {
            state.isLoading = true
            state.personalityProfile = null
        },
        [fetchInvestorPersonalityProfile.fulfilled.type]: (state, action: PayloadAction<PersonalityProfileAPI>) => {
            state.personalityProfile = {
                name: action.payload.name,
                description: action.payload.description,
                riskLevel: action.payload.risk_level,
                riskLevelType: action.payload.risk_level_type,
                generalAllocation: action.payload.general_allocations,
                cryptoAllocation: action?.payload?.crypto_allocations?.map((crypto) => ({
                    level: crypto.level,
                    allocation: crypto.allocation,
                    coins: crypto?.coins?.map((coin) => ({
                        coinId: coin.coin_id,
                        name: coin.coin_name,
                        isStake: coin.is_stake,
                        allocation: coin.allocation
                    }))
                }))
            }
            state.isLoading = false
        }
    }
})

export default investorPersonalityProfileSlicer
