import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchReferralDownline, fetchReferralDownlinePagination } from "./actions"

const referralDownlineSlicer = createSlice({
    name: "referralDownline",
    initialState: {
        referralDownline: null as ReferralDownlineModel[] | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchReferralDownline.pending.type]: (state) => {
            state.isLoading = true
        },

        [fetchReferralDownline.fulfilled.type]: (state, action: PayloadAction<ReferralDownlineAPI[]>) => {
            state.referralDownline = action.payload.map((downline) => {
                const [refId, refName] = downline.id.split(" - ")
                const refNameFormatted = refName.replace(/(^.*\(|\).*$)/g, "")
                const totalCommission = Number(downline.totaltrans) + Number(downline.totalwd) || 0

                return {
                    id: refId,
                    name: refNameFormatted,
                    totalTransaction: Number(downline.totaltrans),
                    totalDownline: Number(downline.totaldownline),
                    totalWithdraw: Number(downline.totalwd),
                    commission: totalCommission,
                    verified: downline.verified === 1
                }
            })

            state.isLoading = false
        },

        [fetchReferralDownlinePagination.pending.type]: (state) => {
            state.isLoading = true
        },

        [fetchReferralDownlinePagination.fulfilled.type]: (state, action: PayloadAction<ReferralDownlineAPI[]>) => {
            state.referralDownline?.push(
                ...action.payload.map((downline) => {
                    const [refId, refName] = downline.id.split(" - ")
                    const refNameFormatted = refName.replace(/(^.*\(|\).*$)/g, "")
                    const totalCommission = Number(downline.totaltrans) + Number(downline.totalwd) || 0

                    return {
                        id: refId,
                        name: refNameFormatted,
                        totalTransaction: Number(downline.totaltrans),
                        totalDownline: Number(downline.totaldownline),
                        totalWithdraw: Number(downline.totalwd),
                        commission: totalCommission,
                        verified: downline.verified === 1
                    }
                })
            )
            state.isLoading = false
        }
    }
})

export default referralDownlineSlicer
