import { HYDRATE } from "next-redux-wrapper"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const userAgentSlicer = createSlice({
    name: "userAgent",
    initialState: {
        userAgent: null as string | null,
        isMobile: null as boolean | null,
        isDesktop: null as boolean | null
    },
    reducers: {
        setUserAgent(
            state,
            action: PayloadAction<{
                userAgent: string | null
                isMobile: boolean
                isDesktop: boolean
            }>
        ) {
            state.userAgent = action.payload.userAgent
            state.isMobile = action.payload.isMobile
            state.isDesktop = action.payload.isDesktop
        }
    },
    extraReducers: {
        [HYDRATE]: (state, action: PayloadAction<any>) => ({
            ...state,
            ...action.payload.userAgent
        })
    }
})

export const { setUserAgent } = userAgentSlicer.actions
export default userAgentSlicer
