// COLOR UPDATE August 11th, 2023

/* EXAMPLE TO USE DARKMODE
textColor: {
    main: "#3D4A5C",
    dark: {
        main: "#E8E9ED",
    }
},
add `dark` before className e.g dark:text-`dark`-main
const Test = tw.span`
    reku-new text-additional dark:text-dark-main p-3 bg-background-subtle dark:bg-dark-background-subtle border-4 border-success-25 dark:border-dark-focus
`
*/

const colors = {
    primary: {
        25: "#E7FEFF",
        50: "#D0F7F8",
        100: "#B9F0F2",
        200: "#8BE2E5",
        300: "#5CD4D8",
        400: "#2EC6CB",
        500: "#00B7BD",
        600: "#009398",
        700: "#006E72",
        800: "#003739",
        900: "#002526",
        DEFAULT: "#00B7BD"
    },
    teal: {
        25: "#E7FEFF",
        50: "#D0F7F8",
        100: "#B9F0F2",
        200: "#8BE2E5",
        300: "#5CD4D8",
        400: "#2EC6CB",
        500: "#00B7BD",
        600: "#009398",
        700: "#006E72",
        800: "#003739",
        900: "#002526",
        DEFAULT: "#00B7BD"
    },
    secondary: {
        25: "#F5F0FE",
        50: "#E9E0FA",
        100: "#DBCDF4",
        200: "#C7B4EE",
        300: "#A688DD",
        400: "#7C5CBC",
        500: "#6A49AB",
        600: "#5B36A1",
        700: "#4D288F",
        800: "#3E1C7D",
        900: "#311664",
        DEFAULT: "#6A49AB"
    },
    purple: {
        25: "#F5F0FE",
        50: "#E9E0FA",
        100: "#DBCDF4",
        200: "#C7B4EE",
        300: "#A688DD",
        400: "#7C5CBC",
        500: "#6A49AB",
        600: "#5B36A1",
        700: "#4D288F",
        800: "#3E1C7D",
        900: "#311664",
        DEFAULT: "#6A49AB"
    },
    neutral: {
        10: "#FFFFFF",
        25: "#F9FAFB",
        50: "#F3F4F7",
        100: "#E7EAEE",
        200: "#DEE2E7",
        300: "#D3D8DF",
        400: "#BEC5D0",
        500: "#95A1B2",
        600: "#6E7D91",
        700: "#3D4A5C",
        800: "#29313D",
        900: "#161B22",
        DEFAULT: "#354050"
    },
    grey: {
        0: "#FFFFFF",
        25: "#F9FAFB",
        50: "#F3F4F7",
        100: "#E7EAEE",
        200: "#DEE2E7",
        300: "#D3D8DF",
        400: "#BEC5D0",
        500: "#95A1B2",
        600: "#6E7D91",
        700: "#3D4A5C",
        800: "#29313D",
        900: "#161B22",
        DEFAULT: "#3D4A5C"
    },
    danger: {
        25: "#FDEBF0",
        50: "#FBD7E1",
        100: "#FAC3D2",
        200: "#F8AFC3",
        300: "#F487A4",
        400: "#F15F86",
        500: "#ED3768",
        600: "#BE2C53",
        700: "#8E213E",
        800: "#5F162A",
        900: "#47111F",
        DEFAULT: "#BE2C53"
    },
    error: {
        25: "#FDEBF0",
        50: "#FBD7E1",
        100: "#FAC3D2",
        200: "#F8AFC3",
        300: "#F487A4",
        400: "#F15F86",
        500: "#ED3768",
        600: "#BE2C53",
        700: "#8E213E",
        800: "#5F162A",
        900: "#47111F",
        DEFAULT: "#ED3768"
    },
    warning: {
        25: "#FFF8E7",
        50: "#FFF1CF",
        100: "#FEEBB8",
        200: "#FEE4A0",
        300: "#FED670",
        400: "#FDC941",
        500: "#FDBB11",
        600: "#CA960E",
        700: "#98700A",
        800: "#654B07",
        900: "#332503",
        DEFAULT: "#FDBB11"
    },
    success: {
        25: "#DEFFFB",
        50: "#D1FAF4",
        100: "#B2F0E8",
        200: "#85E1D5",
        300: "#59D3C2",
        400: "#2CC4B0",
        500: "#00B59D",
        600: "#00917D",
        700: "#006D5E",
        800: "#00483F",
        900: "#00362F",
        DEFAULT: "#00B59D"
    },
    info: {
        25: "#E1EBFF",
        50: "#C7D7FF",
        100: "#ACC2F7",
        200: "#7FA1ED",
        300: "#5480E8",
        400: "#3A6DE4",
        500: "#2558D0",
        600: "#1646B6",
        700: "#103793",
        800: "#072C88",
        900: "#01226A",
        DEFAULT: "#2558D0"
    },
    dark: {
        primary: {
            25: "#003739",
            50: "#004547",
            100: "#00595C",
            200: "#007B80",
            300: "#089BA0",
            400: "#29B8BC",
            500: "#47D2D7",
            600: "#84E8EB",
            700: "#A7EFF1",
            800: "#C9F6F8",
            900: "#DBFEFF",
            DEFAULT: "#47D2D7"
        },
        teal: {
            25: "#003739",
            50: "#004547",
            100: "#00595C",
            200: "#007B80",
            300: "#089BA0",
            400: "#29B8BC",
            500: "#47D2D7",
            600: "#84E8EB",
            700: "#A7EFF1",
            800: "#C9F6F8",
            900: "#DBFEFF",
            DEFAULT: "#47D2D7"
        },
        secondary: {
            25: "#28154C",
            50: "#341867",
            100: "#402079",
            200: "#552F9D",
            300: "#6C45BA",
            400: "#8966CC",
            500: "#B59BE9",
            600: "#CDBCF0",
            700: "#D8C9F3",
            800: "#E3D9F7",
            900: "#E9E2F9",
            DEFAULT: "#B59BE9"
        },
        purple: {
            25: "#28154C",
            50: "#341867",
            100: "#402079",
            200: "#552F9D",
            300: "#6C45BA",
            400: "#8966CC",
            500: "#B59BE9",
            600: "#CDBCF0",
            700: "#D8C9F3",
            800: "#E3D9F7",
            900: "#E9E2F9",
            DEFAULT: "#B59BE9"
        },
        neutral: {
            10: "#211F2D",
            25: "#2C2A3C",
            50: "#39364E",
            100: "#413F5A",
            200: "#494969",
            300: "#565876",
            400: "#7C809C",
            500: "#9C9FB4",
            600: "#C6C8D2",
            700: "#DDDEE4",
            800: "#EEEEF2",
            900: "#FFFFFF",
            DEFAULT: "#DDDEE4"
        },
        grey: {
            0: "#211F2D",
            25: "#2C2A3C",
            50: "#39364E",
            100: "#413F5A",
            200: "#454563",
            300: "#565876",
            400: "#696E8C",
            500: "#888CA5",
            600: "#C6C8D2",
            700: "#E8E9ED",
            800: "#F1F1F4",
            900: "#FFFFFF",
            DEFAULT: "#E8E9ED"
        },
        danger: {
            25: "#550D1A",
            50: "#801327",
            100: "#AA1933",
            200: "#D22242",
            300: "#E93C5B",
            400: "#ED5C76",
            500: "#FF6E88",
            600: "#F8AFC3",
            700: "#FAC3D2",
            800: "#FCD6DD",
            900: "#FEF1F0",
            DEFAULT: "#FF6E88"
        },
        error: {
            25: "#550D1A",
            50: "#801327",
            100: "#AA1933",
            200: "#D22242",
            300: "#E93C5B",
            400: "#ED5C76",
            500: "#FF6E88",
            600: "#F8AFC3",
            700: "#FAC3D2",
            800: "#FCD6DD",
            900: "#FEF1F0",
            DEFAULT: "#FF6E88"
        },
        warning: {
            25: "#4B3602",
            50: "#6F5206",
            100: "#98700A",
            200: "#CA960E",
            300: "#F1B61D",
            400: "#F5C649",
            500: "#FBD573",
            600: "#FEE4A0",
            700: "#FEEBB8",
            800: "#FFF1CF",
            900: "#FFF8E7",
            DEFAULT: "#FBD573"
        },
        success: {
            25: "#00362C",
            50: "#00483A",
            100: "#006D58",
            200: "#009175",
            300: "#0BAA8B",
            400: "#2CC4A7",
            500: "#59D3BB",
            600: "#85E1D0",
            700: "#B2F0E4",
            800: "#C8F8EF",
            900: "#E1FCF7",
            DEFAULT: "#59D3BB"
        },
        info: {
            25: "#0D224F",
            50: "#152C65",
            100: "#173782",
            200: "#254CA7",
            300: "#2C5BC9",
            400: "#5984E8",
            500: "#85A6F4",
            600: "#9DB6F1",
            700: "#B5C9F7",
            800: "#CCDBFF",
            900: "#E1EBFF",
            DEFAULT: "#85A6F4"
        }
    }
}

colors.icon = {
    primary: colors.teal[500],
    secondary: colors.purple[400],
    disabled: colors.grey[400],
    contrast: colors.grey[600],
    "color-bg": colors.grey[0],
    error: colors.error[500],
    warning: colors.warning[500],
    success: colors.success[500],
    info: colors.info[500]
}
colors.dark.icon = {
    primary: colors.dark.teal[500],
    secondary: colors.dark.purple[500],
    disabled: colors.dark.grey[400],
    contrast: colors.dark.grey[600],
    "color-bg": colors.dark.grey[900],
    error: colors.dark.error[500],
    warning: colors.dark.warning[500],
    success: colors.dark.success[500],
    info: colors.dark.info[500]
}

module.exports = {
    colors: {
        ...colors,
        background: {
            subtle: colors.grey[25],
            strong: colors.grey[50],
            DEFAULT: colors.grey[0]
        },
        foreground: {
            teal: colors.teal[25],
            purple: colors.purple[25],
            red: colors.error[25],
            green: colors.success[25],
            yellow: colors.warning[25],
            blue: colors.info[25]
        },
        action: {
            primary: colors.teal[500],
            secondary: colors.purple[400],
            disabled: colors.grey[100],
            danger: colors.error[400]
        },
        dark: {
            ...colors.dark,
            background: {
                subtle: colors.dark.grey[25],
                strong: colors.dark.grey[50],
                DEFAULT: colors.dark.grey[0]
            },
            foreground: {
                teal: colors.dark.teal[50],
                purple: colors.dark.purple[50],
                red: colors.dark.error[25],
                green: colors.dark.success[25],
                yellow: colors.dark.warning[25],
                blue: colors.dark.info[50]
            },
            action: {
                primary: colors.dark.teal[400],
                secondary: colors.dark.purple[400],
                disabled: colors.dark.grey[100],
                danger: colors.dark.error[400]
            }
        }
    },
    textColor: {
        bold: colors.grey[900],
        main: colors.grey[700],
        additional: colors.grey[500],
        disabled: colors.grey[400],
        "color-bg": colors.grey[0],
        dark: {
            bold: colors.dark.grey[900],
            main: colors.dark.grey[700],
            additional: colors.dark.grey[500],
            disabled: colors.dark.grey[400],
            "color-bg": colors.dark.grey[900]
        }
    },
    borderColor: {
        soft: colors.grey[50],
        main: colors.grey[100],
        focus: colors.teal[400],
        error: {
            ...colors.error,
            DEFAULT: colors.error[400]
        },
        success: {
            ...colors.success,
            DEFAULT: colors.success[400]
        },
        dark: {
            soft: colors.dark.grey[50],
            main: colors.dark.grey[100],
            focus: colors.dark.teal[400],
            error: {
                ...colors.dark.error,
                DEFAULT: colors.dark.error[400]
            },
            success: {
                ...colors.dark.success,
                DEFAULT: colors.dark.success[400]
            }
        }
    },
    borderRadius: {
        xs: "8px",
        sm: "16px",
        md: "24px",
        lg: "32px"
    },
    boxShadow: {
        card: "0px 1px 12px rgba(16, 24, 40, 0.06), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        header: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
        footer: "0px -6px 8px -2px rgba(172, 182, 195, 0.1), 0px -1px 4px -2px rgba(53, 64, 80, 0.1)",
        modal: "0px 20px 24px -4px rgba(53, 64, 80, 0.04), 0px 2px 8px -4px rgba(53, 64, 80, 0.08)",
        ring: "0px 0px 0px 4px rgba(231, 254, 255, 0.5)",
        lg: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
        "dark-card": "0px 1px 12px rgba(8, 8, 13, 0.2), 0px 1px 2px rgba(8, 8, 13, 0.2)",
        "dark-header": "0px 6px 8px -2px rgba(8, 8, 13, 0.24), 0px 2px 4px -4px rgba(8, 8, 13, 0.1)",
        "dark-footer": "0px -6px 8px -2px rgba(8, 8, 13, 0.3), 0px -1px 4px -2px rgba(8, 8, 13, 0.1)",
        "dark-modal": "0px 24px 24px -4px rgba(8, 8, 13, 0.25), 0px 4px 8px -4px rgba(8, 8, 13, 0.15)",
        "dark-ring": "0px 0px 0px 4px rgba(0, 55, 57, 0.5)",
        "dark-lg": "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)"
    }
}
