/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { baseFetchApiV3 } from "@api/baseApi"

export const fetchMissionReferrerConfig = createAsyncThunk<MissionReferrerConfigAPI>(
    "missionReferrerConfig/fetchMissionReferrerConfig",
    async () => {
        const response = await baseFetchApiV3<SuccessAPI<MissionReferrerConfigAPI>>({
            url: "/mission/referrer/config",
            method: "GET"
        })

        if (!response.data) {
            throw new Error(response?.message)
        }

        return response.data
    }
)
