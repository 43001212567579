/* eslint-disable no-restricted-globals */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchFamilymemberData } from "./actions"

const familyMemberDataSlice = createSlice({
    name: "familyMemberData",
    initialState: {
        familyMemberData: null as FamilyMemberDataModel[] | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchFamilymemberData.pending.type]: (state) => {
            state.isLoading = true
            state.familyMemberData = null
        },
        [fetchFamilymemberData.fulfilled.type]: (state, action: PayloadAction<FamilyMemberDataAPI[]>) => {
            state.familyMemberData = action.payload
            state.isLoading = false
        },
        [fetchFamilymemberData.rejected.type]: (state) => {
            state.isLoading = false
            state.familyMemberData = null
        }
    }
})

export default familyMemberDataSlice
