import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface FetchOrderBooksPayload {
    coinCode: string
    coinId: number
}

export interface FetchOrderBooksResponse {
    coinId: number
    data: OrderBookAPI
}

const handleFetchOrderBooks = async ({ coinCode, coinId }: FetchOrderBooksPayload) => {
    const response = await baseFetchApi({
        url: "/orderbook",
        params: {
            symbol: `${coinCode}_IDR`
        }
    })

    return { coinId, data: response }
}

export const fetchOrderBooks = createAsyncThunk<FetchOrderBooksResponse, FetchOrderBooksPayload>(
    "orderbooks/fetchOrderbooks",
    handleFetchOrderBooks
)

export const fetchOrderBooksAsync = createAsyncThunk<FetchOrderBooksResponse, FetchOrderBooksPayload>(
    "orderbooks/fetchOrderbooksAsync",
    handleFetchOrderBooks
)
