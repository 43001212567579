import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchGoogleUri } from "./actions"

const googleUriSlicer = createSlice({
    name: "google",
    initialState: {
        google: null as GoogleUriModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchGoogleUri.fulfilled.type]: (state, action: PayloadAction<GoogleUriModel>) => {
            state.google = {
                uri: action.payload.uri
            }
            state.isLoading = false
        },
        [fetchGoogleUri.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default googleUriSlicer
