/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchStakingProducts = createAsyncThunk<StakingProductAPI[]>("staking/fetchStakingProducts", async () => {
    const response = await fetchApi({
        url: "/staking/products",
        method: "GET"
    })

    return response
})
