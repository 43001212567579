import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchEwalletUser } from "./actions"

const ewalletUserSlicer = createSlice({
    name: "ewalletUsers",
    initialState: {
        ewalletUsers: null as EwalletUserModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchEwalletUser.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchEwalletUser.fulfilled.type]: (state, action: PayloadAction<EwalletUserAPI[]>) => {
            state.ewalletUsers = action.payload.map((ewalletuser) => ({
                id: ewalletuser.id,
                name: ewalletuser.ewallet_name,
                logo: ewalletuser.logo,
                accountName: ewalletuser.ewalletaccountname,
                accountNumber: ewalletuser.ewalletaccount,
                isPrimary: ewalletuser.primaryewallet === "1"
            }))
            state.isLoading = false
        }
    }
})

export default ewalletUserSlicer
