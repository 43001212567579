import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const otpVerifySlicer = createSlice({
    name: "otpVerify",
    initialState: {
        otp: "",
        otp_email: ""
    },
    reducers: {
        changeOtpVerify: (state, action: PayloadAction<OtpVerifyModel>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeOtpVerify } = otpVerifySlicer.actions
export default otpVerifySlicer
