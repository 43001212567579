import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchEWallet } from "./actions"

const eWalletsSlicer = createSlice({
    name: "eWallets",
    initialState: {
        eWallets: null as EWalletModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchEWallet.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchEWallet.fulfilled.type]: (state, action: PayloadAction<EWalletAPI[]>) => {
            state.eWallets = action.payload.map((eWallet) => ({
                id: eWallet.id,
                name: eWallet.ewallet,
                minimum: eWallet.minamount,
                maximum: eWallet.maxamount,
                fee: eWallet.fee,
                logo: eWallet.logo,
                deposit: {
                    enabled: Boolean(eWallet.enabledepo),
                    notes: eWallet.notesdepo
                },
                withdraw: {
                    enabled: Boolean(eWallet.enablewd),
                    notes: eWallet.noteswd
                }
            }))
            state.isLoading = false
        }
    }
})

export default eWalletsSlicer
