import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchGoogleUri = createAsyncThunk<GoogleUriModel>("google/fetchGoogleUri", async () => {
    const response = await baseFetchApiV3({
        url: "/sso/uri",
        method: "GET"
    })

    return response.result
})
