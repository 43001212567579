import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: IError = {
    status: false,
    code: 0,
    message: ""
}

const errorSlicer = createSlice({
    name: "error",
    initialState,
    reducers: {
        setError: (_, action: PayloadAction<IError>) => action.payload,
        resetError: () => initialState
    }
})

export const { setError, resetError } = errorSlicer.actions
export default errorSlicer
