export const EVENT_SUBMIT_SELL = "SUBMIT_SELL"
export const EVENT_SUBMIT_BUY = "SUBMIT_BUY"
export const EVENT_CANCEL_ORDER_BUY = "CANCEL_BUY"
export const EVENT_CANCEL_ORDER_SELL = "CANCEL_SELL"
export const EVENT_CANCEL_WITHDRAW = "CANCEL_WITHDRAW"
export const EVENT_WITHDRAW = "WITHDRAW"
export const EVENT_LOGIN = "LOGIN"
export const EVENT_LOGOUT = "LOGOUT"
export const EVENT_SUBMIT_VERIFICATION = "SUBMIT_VERIFICATION"
export const EVENT_REGISTER = "REGISTER"
