import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchAnnouncement } from "./actions"

const announcementSlicer = createSlice({
    name: "announcement",
    initialState: {
        announcement: null as AnnouncementAPI[] | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchAnnouncement.fulfilled.type]: (state, action: PayloadAction<AnnouncementAPI[]>) => {
            state.announcement = action.payload
            state.isLoading = false
        }
    }
})

export default announcementSlicer
