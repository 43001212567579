import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchReferralCommission } from "./actions"

const referralCommissionSlicer = createSlice({
    name: "referralCommission",
    initialState: {
        referralCommission: null as ReferralCommissionModel[] | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchReferralCommission.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchReferralCommission.fulfilled.type]: (state, action: PayloadAction<ReferralCommissionAPI[]>) => {
            state.referralCommission = action.payload.map((commission) => ({
                code: commission.code,
                name: commission.name,
                total: commission.total
            }))

            state.isLoading = false
        }
    }
})

export default referralCommissionSlicer
