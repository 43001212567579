import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface VerifyPhoneArgs {
    token: string
    phonenum: string
    smstoken: string
}

export const fetchVerifyPhone = createAsyncThunk<boolean, VerifyPhoneArgs>(
    "status/verifyPhone",
    async ({ token, smstoken, phonenum }, { rejectWithValue }) => {
        try {
            const formData = new FormData()
            formData.append("token", token)
            formData.append("smstoken", smstoken)
            formData.append("phonenum", phonenum)

            const response = await baseFetchApi({
                url: "/verifyphone",
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            })

            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)
