import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchReferralCommission = createAsyncThunk<ReferralCommissionAPI>(
    "referral/fetchReferralCommission",
    async () => {
        const response = await baseFetchApi({
            url: "/commissions",
            method: "GET"
        })
        return response
    }
)
