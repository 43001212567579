import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchAnnouncement = createAsyncThunk<AnnouncementAPI>("announcement/fetchAnnouncement", async () => {
    const response = await baseFetchApiV3({
        url: "/announcements"
    })

    return response
})
