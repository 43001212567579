import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getAuth } from "@helpers/auth"
import { ChangePasswordModel } from "@type/model/change-password"
import { fetchChangePassword } from "./action"

const auth = getAuth()

const changePasswordSlicer = createSlice({
    name: "generateTwoFa",
    initialState: {
        token: auth.token || null,
        otp: null,
        pwd: null,
        oldpwd: null,
        isLoading: false
    } as unknown as ChangePasswordModel,
    reducers: {},
    extraReducers: {
        [fetchChangePassword.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchChangePassword.fulfilled.type]: (state, action: PayloadAction<ChangePasswordModel>) => {
            state.token = action.payload?.token
            state.otp = action.payload?.otp
            state.pwd = action.payload?.pwd
            state.oldpwd = action.payload?.oldpwd
        },
        [fetchChangePassword.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default changePasswordSlicer
