/* eslint-disable no-restricted-globals */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchFamilyOwnerData } from "./actions"

const familyOwnerDataSlice = createSlice({
    name: "familyOwnerData",
    initialState: {
        familyOwnerData: null as FamilyOwnerDataModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchFamilyOwnerData.pending.type]: (state) => {
            state.isLoading = true
            state.familyOwnerData = null
        },
        [fetchFamilyOwnerData.fulfilled.type]: (state, action: PayloadAction<FamilyOwnerDataAPI>) => {
            state.familyOwnerData = action.payload
            state.isLoading = false
        },
        [fetchFamilyOwnerData.rejected.type]: (state) => {
            state.isLoading = false
            state.familyOwnerData = null
        }
    }
})

export default familyOwnerDataSlice
