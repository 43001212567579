import createWebStorage from "redux-persist/lib/storage/createWebStorage"

const createNoopStorage = () => {
    const getItem = () => Promise.resolve(null)
    const setItem = (_key: any, value: any) => Promise.resolve(value)
    const removeItem = () => Promise.resolve()

    return {
        getItem,
        setItem,
        removeItem
    }
}

const storage = process.browser ? createWebStorage("local") : createNoopStorage()

export default storage
