/* eslint-disable import/prefer-default-export */
export const TOKEN_TIMEOUT = 106
export const TOKEN_INVALID = 101
export const ACCESS_DENIED = 403
export const PROBLEMATIC_TOKEN_VALIDATION = 270
export const UNAUTHORIZED = 401

export const FORGOT_PASSWORD_W_GOOGLE = 453
export const EMAIL_REGISTER = 452
export const RESET_PASSWORD_URL_EXPIRED = 472

export const TRADING_DISABLED = 404

export const MAINTENANCE = 110
export const OTC_MAINTENANCE = 601
export const WRONG_EMAIL_PASSWORD = 140

export const IP_SUSPENDED = 138

export const USER_SUSPEND = 120
export const USER_SUSPEND_IN = 139
export const USER_SUSPENDED = 168

export const KEY_SETTING_NOT_FOUND = 187

export const REFERRAL_NOT_FOUND = 176
export const NO_PHONE_NUMBER = 74

export const WRONG_MIN_AMOUNT = 134

export const WRONG_OTP = 116
export const WRONG_PROMOCODE = 125
export const PROMOCODE_USED = 127

export const FAMILY_INVALID_CODE = 179
export const FAMILY_SELF_CODE = 182

export const CREATE_COIN_ADDRESS = 475

export const EMPTY_PNL_SUMMARY = 404000002

export const LOGIN_EMAIL_SEND = 480
export const LOGIN_EMAIL_CONFIRMATION = 481
export const LOGIN_SUSPICIOUS = 482
export const LOGIN_RESET_PASSWORD = 483
