import { createAsyncThunk } from "@reduxjs/toolkit"

import { baseFetchApiV3 } from "@api/baseApi"

export const sendOtpEmail = createAsyncThunk<SuccessAPI, SendOTPEmailApi>(
    "sendOtpEmail/sendOtpEmail",
    async ({ amount, internaltransfer }) => {
        const response = await baseFetchApiV3({
            url: "/sendemailotpwd",
            method: "POST",
            data: {
                amount,
                internaltransfer
            }
        })

        return response
    }
)
