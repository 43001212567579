import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sendSms } from "./actions"

const sendSmsSlicer = createSlice({
    name: "sendSms",
    initialState: {
        sendSms: null as SendSMSModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [sendSms.pending.type]: (state) => {
            state.isLoading = true
            state.sendSms = null
        },
        [sendSms.fulfilled.type]: (state, action: PayloadAction<SendSMSAPI>) => {
            state.sendSms = {
                success: Boolean(action.payload.success),
                phoneNumber: action.payload.nohp
            }

            state.isLoading = false
        },
        [sendSms.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default sendSmsSlicer
