// eslint-disable-next-line import/no-cycle
import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchInvestorPersonalityProfile = createAsyncThunk(
    "investorPersonality/fetchInvestorPersonalityProfile",
    async () => {
        const response = await baseFetchApiV3<BaseResponseAPIV3<PersonalityProfileAPI>>({
            url: "/mission/personality-test/profile",
            method: "GET"
        })

        return response.data
    }
)
