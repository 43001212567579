import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const simpleTradePreferences = createSlice({
    name: "simpleTradePreferences",
    initialState: {
        orderType: "buy" as OrderType,
        unit: "idr" as Unit,
        showPreview: false,
        isSuccess: false
    },
    reducers: {
        changeSimpleTradePreference: (state, action: PayloadAction<SimpleTradePreferencesModel>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeSimpleTradePreference } = simpleTradePreferences.actions
export default simpleTradePreferences
