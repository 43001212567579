import axios, { AxiosAdapter, AxiosRequestConfig } from "axios"
import { throttleAdapterEnhancer } from "axios-extensions"

const client = axios.create({
    baseURL: "https://api-blog.reku.id/wp-json/wp/v2",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
    adapter: throttleAdapterEnhancer(axios.defaults.adapter as AxiosAdapter)
})

const blogFetchApi = async <T = any>(
    config: AxiosRequestConfig
): Promise<{ data: T; totalPages: number; totalPosts: number }> => {
    const request = await client(config)
    return {
        data: request.data,
        totalPages: Number(request.headers["x-wp-totalpages"] ?? 1),
        totalPosts: Number(request.headers["x-wp-total"] ?? 0)
    }
}

export default blogFetchApi
