class ApiError extends Error {
    code?: number

    data: any

    constructor(message: string, data: any, code?: number) {
        super(message)
        this.name = this.constructor.name
        this.code = code
        this.data = data

        Object.setPrototypeOf(this, ApiError.prototype)

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor)
        }
    }
}

export default ApiError
