import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { REHYDRATE } from "redux-persist"

import { fetchReferralIdentity } from "./actions"

const referralIdentitySlicer = createSlice({
    name: "referralIdentity",
    initialState: {
        referralIdentity: null as ReferralIdentityModel | null,
        isLoading: true,
        rehydrate: false
    },
    reducers: {},
    extraReducers: {
        [fetchReferralIdentity.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchReferralIdentity.fulfilled.type]: (state, action: PayloadAction<ReferralIdentityAPI>) => {
            state.referralIdentity = {
                email: action.payload.email,
                name: action.payload.refname,
                alias: action.payload.alias,
                referralUrl: action.payload.ref_url
            }

            state.isLoading = false
        },
        [REHYDRATE]: (state, action) => {
            if (action.payload?.referralIdentity) {
                state.referralIdentity = action.payload.referralIdentity.referralIdentity
                state.isLoading = action.payload.referralIdentity.isLoading
            }

            state.isLoading = false
            state.rehydrate = true
        }
    }
})

export default referralIdentitySlicer
