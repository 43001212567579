import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchReferralBalance } from "./actions"

const referralBalanceSlicer = createSlice({
    name: "referralBalance",
    initialState: {
        referralBalance: null as ReferralBalanceModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchReferralBalance.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchReferralBalance.fulfilled.type]: (state, action: PayloadAction<ReferralBalanceAPI>) => {
            state.referralBalance = action.payload
            state.isLoading = false
        }
    }
})

export default referralBalanceSlicer
