import { createSlice } from "@reduxjs/toolkit"

const contentOverlaySlice = createSlice({
    name: "contentOverlay",
    initialState: {
        isOpen: false
    },
    reducers: {
        openOverlay: (state) => {
            state.isOpen = true
        },
        closeOverlay: (state) => {
            state.isOpen = false
        }
    }
})

export const { openOverlay, closeOverlay } = contentOverlaySlice.actions
export default contentOverlaySlice
