import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchCoinMultipleChainWalletBooks, fetchCoinWalletBooks } from "./actions"

const coinWalletBookSlicer = createSlice({
    name: "coinWalletBook",
    initialState: {
        coinWalletBook: null as CoinWalletBooksModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchCoinMultipleChainWalletBooks.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCoinMultipleChainWalletBooks.fulfilled.type]: (
            state,
            action: PayloadAction<CoinMultipleChainWalletBooksAPI>
        ) => {
            state.coinWalletBook = {
                total: action.payload.total,
                walletAddress: action.payload.wallet_address.map((wallet) => ({
                    id: String(wallet.id),
                    address: wallet.address,
                    walletName: wallet.wallet_name,
                    chainId: wallet.chain_id,
                    chainName: wallet.chain_name,
                    chainCode: wallet.chain_code
                }))
            }

            state.isLoading = false
        },

        [fetchCoinWalletBooks.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCoinWalletBooks.fulfilled.type]: (state, action: PayloadAction<CoinWalletBooksAPI>) => {
            state.coinWalletBook = {
                total: action.payload.length,
                walletAddress: action.payload.map((wallet) => ({
                    id: wallet.id,
                    address: wallet.address,
                    walletName: wallet.walletname
                }))
            }

            state.isLoading = false
        }
    }
})

export default coinWalletBookSlicer
