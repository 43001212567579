import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchPortfolioSummaryAction } from "./actions"

const portfolioSummarySlice = createSlice({
    name: "portfolioSummary",
    initialState: {
        portfolioSummary: null as PortfolioSummaryModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchPortfolioSummaryAction.pending.type]: (state) => {
            state.portfolioSummary = null
            state.isLoading = true
        },
        [fetchPortfolioSummaryAction.fulfilled.type]: (state, action: PayloadAction<PortfolioSummaryAPI>) => {
            state.portfolioSummary = {
                total: action.payload.total_asset_value,
                amount: action.payload.pl_amount,
                percentage: action.payload.pl_percentage
            }
            state.isLoading = false
        },
        [fetchPortfolioSummaryAction.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default portfolioSummarySlice
