import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"

import { fetchOTCHistory, fetchOTCHistoryPagination } from "./actions"

const otcHistorySlicer = createSlice({
    name: "OTCHistory",
    initialState: {
        otcHistory: null as OTCHistoryModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchOTCHistory.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchOTCHistory.fulfilled.type]: (state, action: PayloadAction<OTCHistoryAPI[]>) => {
            state.otcHistory = action.payload
            state.isLoading = false
        },
        [fetchOTCHistoryPagination.fulfilled.type]: (state, action: PayloadAction<OTCHistoryAPI[]>) => {
            state.otcHistory?.push(...action.payload)
        },
        [HYDRATE]: (state, action: PayloadAction<{ OTCHistory: { otcHistory: OTCHistoryModel[] } }>) => {
            if (action.payload?.OTCHistory?.otcHistory) {
                state.otcHistory = action.payload.OTCHistory.otcHistory
                state.isLoading = false
            }
        }
    }
})

export default otcHistorySlicer
