import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const simpleTradeFormSlicer = createSlice({
    name: "simpleTradeForm",
    initialState: {
        isTouched: false,
        error: "",
        amount: 0,
        estimated: 0,
        selectedPct: 0
    },
    reducers: {
        changeSimpleTradeForm: (state, action: PayloadAction<SimpleTradeFormState>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeSimpleTradeForm } = simpleTradeFormSlicer.actions
export default simpleTradeFormSlicer
