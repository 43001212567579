import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface CoinPieChartArgs {
    page?: number
    count?: number
}

export const fetchCoinPieChart = createAsyncThunk<PortfolioCoinPieChartAPI, CoinPieChartArgs>(
    "portfolio/fetchCoinPieChart",
    async ({ page = 1, count = 10 }) => {
        const response = await baseFetchApiV3<PortfolioCoinPieChartAPI>({
            url: "/pnl/coin/pie",
            params: {
                page,
                limit: count
            }
        })

        return response
    }
)
