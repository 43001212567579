import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchWalletDetail = createAsyncThunk<WalletDetailAPI, string>(
    "walletDetail/fetchWalletDetail",
    async (coinCode) => {
        const response = await baseFetchApi({
            url: "/walletdetail",
            method: "POST",
            data: {
                id: coinCode
            }
        })

        return response
    }
)
