import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchPlCoinList = createAsyncThunk<PlCoinListApi, PlCoinListPayload>(
    "pl/coinList",
    async ({ page, pageSize }: PlCoinListPayload) => {
        const response = await baseFetchApiV3({
            url: "/pnl/coin/pie",
            method: "GET",
            params: {
                page,
                limit: pageSize
            }
        })

        return response.data
    }
)
