import * as React from "react"

import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"

const spinner = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`

const SVGAnimation = styled.svg`
    animation: ${spinner} 1s linear infinite;
`

const Oval: React.FC<LoaderProps> = ({ width, height, color, label, radius }: LoaderProps) => (
    <SVGAnimation
        width={width}
        height={height}
        viewBox='0 0 38 38'
        xmlns='http://www.w3.org/2000/svg'
        stroke={color}
        aria-label={label}
    >
        <g fill='none' fillRule='evenodd'>
            <g transform='translate(1 1)' strokeWidth='2'>
                <circle strokeOpacity='.5' cx='18' cy='18' r={radius} />
                <path d='M36 18c0-9.94-8.06-18-18-18' />
            </g>
        </g>
    </SVGAnimation>
)

Oval.defaultProps = {
    radius: 18
}

export default Oval
