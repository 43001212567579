/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import baseFetchApi from "@api/baseApi"

export const fetchStakingBalancesMemberAction = createAsyncThunk<StakingBalanceApi[], { hash: string }>(
    "staking/fetchStakingBalancesMember",
    async ({ hash }) => {
        const response = await baseFetchApi({
            url: `/staking/balances/${hash}`,
            method: "GET"
        })

        return response
    }
)
