import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchEWallet = createAsyncThunk<EWalletAPI[]>("ewallet/fetchEWallet", async () => {
    const response = await fetchApi({
        url: "/ewallet",
        method: "GET"
    })

    return response
})
