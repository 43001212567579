import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchPlCalendarbar } from "./actions"

const plCalendarBarSlicer = createSlice({
    name: "plCalendar",
    initialState: {
        isLoading: false,
        plCalendar: [] as PlCalendarModel[]
    },
    reducers: {},
    extraReducers: {
        [fetchPlCalendarbar.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchPlCalendarbar.fulfilled.type]: (state, action: PayloadAction<PlCalendarAPI[]>) => {
            state.plCalendar = action.payload.map((item) => ({
                amount: item.a,
                percentage: item.p,
                time: item.t
            }))
            state.isLoading = false
        },
        [fetchPlCalendarbar.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default plCalendarBarSlicer
