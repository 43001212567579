import styled from "@emotion/styled"
import { getAlign, getJustify } from "./util"

interface Props {
    /**
     * Align items
     */
    align?: "start" | "end" | "center" | "baseline"
    /**
     * Justify content
     */
    justify?: "start" | "end" | "center" | "between"
    /**
     * The space direction
     */
    direction?: "horizontal" | "vertical"
    /**
     * The space size
     */
    size?: "small" | "middle" | "large"
}

const Space = styled.div<Props>`
    display: inline-flex;
    width: auto;
    flex-direction: ${({ direction }) => (direction === "vertical" ? "column" : "row")};
    align-items: ${({ align }) => getAlign(align)};
    justify-content: ${({ justify }) => getJustify(justify)};
    gap: ${({ theme, size }) => `${theme.spacing[size || "small"]}px`};
`

Space.defaultProps = {
    align: undefined,
    direction: "horizontal",
    size: "small"
}

export default Space
