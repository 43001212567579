import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sendOtpEmail } from "./actions"

const sendOtpEmailSlicer = createSlice({
    name: "sendOtpEmail",
    initialState: {
        sendOtpEmail: null as SendOTPEmailModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [sendOtpEmail.pending.type]: (state) => {
            state.isLoading = true
            state.sendOtpEmail = null
        },
        [sendOtpEmail.fulfilled.type]: (state, action: PayloadAction<SendOTPEmailApi>) => {
            state.sendOtpEmail = {
                amount: action.payload.amount,
                internaltransfer: action.payload.internaltransfer
            }

            state.isLoading = false
        },
        [sendOtpEmail.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default sendOtpEmailSlicer
