import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchPlacementAnnouncement = createAsyncThunk<PlacementAnnouncementAPI>(
    "placementAnnouncement/fetchPlacementAnnouncement",
    async () => {
        const response = await baseFetchApiV3({
            url: "/announcements/highlight"
        })

        return response
    }
)
