import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchDepthChart = createAsyncThunk("depthChart/fetchDepthChart", async (coinCode: string) => {
    const response = await baseFetchApi({
        url: "/orderbookall",
        params: {
            symbol: `${coinCode}_IDR`
        }
    })

    return response
})
