import { getAuth } from "@helpers/auth"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchCancelInternalTf } from "./actions"

const auth = getAuth()
const cancelInternalTfSlicer = createSlice({
    name: "cancelInternalTf",
    initialState: {
        hash: auth.hash || null,
        token: auth.token || null,
        isLoading: false
    } as unknown as CancelInternalTransferModel,
    reducers: {},
    extraReducers: {
        [fetchCancelInternalTf.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCancelInternalTf.fulfilled.type]: (state, action: PayloadAction<CancelInternalTransferModel>) => {
            state.hash = action.payload?.hash
            state.token = action.payload?.token
            state.isLoading = false
        },
        [fetchCancelInternalTf.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default cancelInternalTfSlicer
