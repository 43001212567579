import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"

import { fetchOTCChart } from "./actions"

const otcChartSlicer = createSlice({
    name: "OTCChart",
    initialState: {
        otcChart: null as number[][] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchOTCChart.pending.type]: (state) => {
            state.isLoading = true
            state.otcChart = null
        },
        [fetchOTCChart.fulfilled.type]: (state, action: PayloadAction<number[][]>) => {
            state.otcChart = action.payload
            state.isLoading = false
        },
        [HYDRATE]: (state, action: PayloadAction<{ OTCChart: { otcChart: number[][] } }>) => {
            if (action.payload?.OTCChart?.otcChart) {
                state.otcChart = action.payload.OTCChart.otcChart
                state.isLoading = false
            }
        }
    }
})

export default otcChartSlicer
