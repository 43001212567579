import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchPersonalityTestQuestion = createAsyncThunk<PersonalityTestQuestionAPI>(
    "personalityTestQuestion/fetchPersonalityTestQuestion",
    async () => {
        const res = await baseFetchApiV3<BaseResponseAPIV3<PersonalityTestQuestionAPI>>({
            url: "mission/personality-test/questions",
            method: "GET"
        })

        return res.data
    }
)

export type SubmitPersonalityTestQuestionArgs = Array<{
    id: number
    answerId: number
}>

export const submitPersonalityTestQuestion = createAsyncThunk(
    "personalityTestQuestion/submitPersonalityTestQuestion",
    async (data: SubmitPersonalityTestQuestionArgs) => {
        const res = await baseFetchApiV3({
            url: "mission/personality-test/questions",
            method: "POST",
            data: data.map((item) => ({
                id: item.id,
                answer_id: item.answerId
            })),
            headers: {
                "Content-Type": "application/json"
            }
        })

        return res.data
    }
)
