import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface Success {
    message: string
}
export const fetchFamilyOwnerData = createAsyncThunk("family/fetchFamilyOwnerData", async () => {
    const response = await baseFetchApiV3<BaseResponseAPIV3<FamilyOwnerDataAPI>>({
        url: "/user/family/owner",
        method: "GET"
    })

    return response.data
})

export const disconnectOwnerAction = createAsyncThunk("family/disconnectFamilyOwner", async (_, { dispatch }) => {
    const response = await baseFetchApiV3<Success>({
        url: "/user/family/owner/disconnect",
        method: "DELETE"
    })
    dispatch(fetchFamilyOwnerData())

    return response
})
