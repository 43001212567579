import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getOrderType } from "@utils/order-type"
import BigNumber from "bignumber.js"

import { fetchOrderHistory, fetchOrderHistoryPagination } from "./actions"

const convertToModel = (lists: OrderHistoryAPI[]): OrderHistoryModel[] =>
    lists.map((data) => {
        const fee = data[6]
        const vatCoin = data[15]
        const feeCoin = new BigNumber(fee).minus(vatCoin).toNumber()
        const feeIDR = new BigNumber(feeCoin).times(data[3]).toNumber()
        const orderType = getOrderType(data[2])

        return {
            datetime: data[0],
            coinCode: data[1],
            orderType,
            price: data[3],
            total: data[4],
            amount: data[5],
            fee: {
                original_fee: orderType === "buy" ? feeCoin : feeIDR,
                vat: {
                    idr: data[16],
                    coin: vatCoin
                }
            },
            taker: data[10],
            migaId: data[11],
            migaInstantId: data[12],
            walletId: data[13]
        }
    })

const orderHistorySlicer = createSlice({
    name: "orderHistory",
    initialState: {
        orderHistory: null as OrderHistoryModel[] | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchOrderHistory.fulfilled.type]: (state, action: PayloadAction<OrderHistoryAPI[]>) => {
            state.orderHistory = convertToModel(action.payload)
            state.isLoading = false
        },
        [fetchOrderHistoryPagination.fulfilled.type]: (state, action: PayloadAction<OrderHistoryAPI[]>) => {
            state.orderHistory?.push(...convertToModel(action.payload))
        }
    }
})

export default orderHistorySlicer
