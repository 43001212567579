import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchDetailTrade } from "./actions"

const detailtradeSlicer = createSlice({
    name: "detailTrade",
    initialState: {
        detailTrade: null as DetailTradeModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchDetailTrade.pending.type]: (state) => {
            state.isLoading = true
            state.detailTrade = null
        },
        [fetchDetailTrade.fulfilled.type]: (state, action: PayloadAction<DetailTradeModel[]>) => {
            state.detailTrade = action.payload
            state.isLoading = false
        },
        [fetchDetailTrade.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default detailtradeSlicer
