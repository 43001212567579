import dynamic from "next/dynamic"

const EmptyStateIllustration = {
    NoAlert: dynamic(() => import("./NoAlert")),
    NoAPI: dynamic(() => import("./NoAPI")),
    NoBankAccount: dynamic(() => import("./NoBankAccount")),
    NoCoin: dynamic(() => import("./NoCoin")),
    NoConnection: dynamic(() => import("./NoConnection")),
    NoGraph: dynamic(() => import("./NoGraph")),
    NoList: dynamic(() => import("./NoList")),
    NoNotification: dynamic(() => import("./NoNotification")),
    NoPendingOrder: dynamic(() => import("./NoPendingOrder")),
    NoPhone: dynamic(() => import("./NoPhone")),
    NoSearchResult: dynamic(() => import("./NoSearchResult"))
}

export default EmptyStateIllustration
