import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface DownlineArgs {
    offset: number
}

export const fetchReferralDownline = createAsyncThunk<ReferralDownlineAPI, DownlineArgs>(
    "referral/fetchReferralDownline",
    async ({ offset }) => {
        const response = await baseFetchApi({
            url: "/downline",
            method: "POST",
            data: {
                offset
            }
        })
        return response
    }
)

export const fetchReferralDownlinePagination = createAsyncThunk<ReferralDownlineAPI, DownlineArgs>(
    "referral/fetchReferralDownlinePagination",
    async ({ offset }) => {
        const response = await baseFetchApi({
            url: "/downline",
            method: "POST",
            data: {
                offset
            }
        })
        return response
    }
)
