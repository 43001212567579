import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { StakingProductModel } from "@type/model/staking-product"
import { fetchStakingProducts } from "./actions"

const stakingProducts = createSlice({
    name: "stakingProducts",
    initialState: {
        stakingProducts: null as StakingProductModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchStakingProducts.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchStakingProducts.fulfilled.type]: (state, action: PayloadAction<StakingProductAPI[]>) => {
            state.stakingProducts = action.payload.map((product) => ({
                id: product.id,
                productId: product.product_id,
                name: product.product_name,
                code: product.product_code,
                logo: product.product_logo,
                apy: product.apy || 0,
                status: product.status,
                periods: product.periods
                    ? product.periods.map((period) => ({
                          id: period.id,
                          productPeriodId: period.product_period_id,
                          durations: period.durations,
                          minimumAmount: period.minimum_amount,
                          maximumAmount: period.maximum_amount,
                          minimumRedemption: period.minimum_redemption,
                          subscribeType: period.subscribe_type,
                          apy: period.apy,
                          status: period.status
                      }))
                    : [],
                order: product.order
            }))
            state.isLoading = false
        }
    }
})

export default stakingProducts
