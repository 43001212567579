import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { closeOverlay, openOverlay } from "@redux/content-overlay/slicer"

const useContentOverlay = () => {
    const { isOpen } = useAppSelector((state) => state.contentOverlay)
    const dispatch = useAppDispatch()

    const handleOpenOverlay = () => {
        dispatch(openOverlay())
    }

    const handleCloseOverlay = () => {
        dispatch(closeOverlay())
    }

    return { isOpen, openOverlay: handleOpenOverlay, closeOverlay: handleCloseOverlay }
}

export default useContentOverlay
