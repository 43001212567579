import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const internalTransferConfirm = createAsyncThunk<InternalTransferAPI, string>(
    "emailConfirmation/internalTransferConfirm",
    async (hash: string) => {
        const response = await baseFetchApi({
            url: `/internaltransfer/${hash}`
        })

        return response
    }
)

interface SendCoinConfirmParams {
    hash: string
    type: "sendcoin" | "verifycoin"
}

export const sendCoinConfirm = createAsyncThunk<SendCoinConfirmAPI, SendCoinConfirmParams>(
    "emailConfirmation/sendCoinConfirm",
    async ({ hash, type }) => {
        const response = await baseFetchApi({
            url: type === "sendcoin" ? `/sendfundnow/${hash}` : `/wallet/verify/coin/${hash}`
        })

        return response
    }
)

export const activateAccount = createAsyncThunk<SuccessV2API, string>(
    "emailConfirmation/activateAccount",
    async (hash) => {
        const response = await baseFetchApi({
            url: `/activate/${hash}`
        })

        return response
    }
)
