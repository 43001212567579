import { useCallback, useEffect } from "react"
import { useRouter } from "next/router"

import { DARKMODE_COOKIE_KEY } from "@config/darkmode"
import { getCookie, setCookie } from "@helpers/cookies"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import storeRegistry from "@redux/registry"
import { changeUserPreference } from "@redux/user-preferences/slicer"
import { isBrowser } from "@utils/browser"

const changeDocumentTheme = (darkMode: boolean) => {
    if (isBrowser) {
        document.documentElement.dataset.theme = darkMode ? "dark" : "light"
    }
}

const useUserPreferences = () => {
    let data = useAppSelector((state) => state.userPreferences)
    const dispatch = useAppDispatch()
    const router = useRouter()

    const update = useCallback(
        (preferences: UserPreferencesModel) => {
            dispatch(changeUserPreference(preferences))

            if (typeof preferences.darkMode !== "undefined") {
                setCookie(DARKMODE_COOKIE_KEY, String(Number(preferences.darkMode)))
                changeDocumentTheme(preferences.darkMode)
            }
        },
        [dispatch]
    )

    useEffect(() => {
        const isDarkMode = getCookie(DARKMODE_COOKIE_KEY)

        update({
            darkMode: isDarkMode ? Boolean(Number(isDarkMode)) : false
        })
    }, [update, router.asPath])

    const darkModePath = ["/otc"]
    const lightModePath = ["/financeflash"]

    const currentPath = router.pathname.toLocaleLowerCase()

    const isDarkModePath = Boolean(darkModePath.find((e) => e === currentPath))
    const isLightModePath = Boolean(lightModePath.find((e) => e === currentPath))

    if (isDarkModePath) {
        data = {
            ...data,
            darkMode: true
        }
        changeDocumentTheme(true)
    }

    if (isLightModePath) {
        data = {
            ...data,
            darkMode: false
        }
        changeDocumentTheme(false)
    }

    return {
        data,
        update
    }
}

export const setUserPreferences = (preferences: UserPreferencesModel) => {
    storeRegistry.getStore().dispatch(changeUserPreference(preferences))

    if (typeof preferences.darkMode !== "undefined") {
        changeDocumentTheme(preferences.darkMode)
    }
}

export default useUserPreferences
