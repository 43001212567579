/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchStakingBalances = createAsyncThunk<StakingBalanceApi[], string | undefined>(
    "staking/balances",
    async (hash) => {
        const response = await fetchApi({
            url: hash ? `/staking/balances/${hash}` : "/staking/balances",
            method: "GET"
        })

        return response
    }
)
