import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface RemovePhoneArgs {
    token: string
    otp: string
    id: string
}

export const fetchRemovePhone = createAsyncThunk<boolean, RemovePhoneArgs>(
    "status/removePhone",
    async ({ token, id, otp }, { rejectWithValue }) => {
        try {
            const formData = new FormData()
            formData.append("token", token)
            formData.append("id", id)
            formData.append("otp", otp)

            const response = await baseFetchApi({
                url: "/removephone",
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            })

            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)
