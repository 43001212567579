import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import BigNumber from "bignumber.js"
import { fetchOrderBooks, fetchOrderBooksAsync, FetchOrderBooksResponse } from "./actions"

const convertToModel = (data: OrderBookAPI): OrderBookModel => {
    if (!data.b && !data.s) {
        return {
            bids: [],
            asks: []
        }
    }

    const bidsSortByTotalCoin = data.b.sort((a, b) => b[0] - a[0])
    const bidsMaxTotalIdr = bidsSortByTotalCoin?.[0]?.[0]

    const asksSortByTotalCoin = data.s.sort((a, b) => b[0] - a[0])
    const asksMaxTotalIdr = asksSortByTotalCoin?.[0]?.[0]

    let amountBidsCoin = 0
    let amountBidsIdr = 0
    let totalBidsPrice = 0

    let amountAsksCoin = 0
    let amountAsksIdr = 0
    let totalAsksPrice = 0

    return {
        bids: data.b
            .sort((a, b) => b[1] - a[1])
            .map(([idr, price, coin], index) => {
                let percentage = 0

                if (bidsMaxTotalIdr) {
                    percentage = (idr / bidsMaxTotalIdr) * 100
                    percentage = percentage < 1 ? 1 : percentage
                }

                amountBidsCoin += coin
                amountBidsIdr += idr

                totalBidsPrice += new BigNumber(price).multipliedBy(coin).toNumber()

                const priceAverage =
                    index === 0 ? price : new BigNumber(totalBidsPrice).dividedBy(amountBidsCoin).toNumber()

                return {
                    idr,
                    price,
                    coin,
                    barPercentage: percentage,
                    priceAverage,
                    amountCoin: amountBidsCoin,
                    amountIdr: amountBidsIdr
                }
            })
            .sort((a, b) => b.price - a.price),
        asks: data.s
            .sort((a, b) => a[1] - b[1])
            .map(([idr, price, coin], index) => {
                let percentage = 0

                if (asksMaxTotalIdr) {
                    percentage = (idr / asksMaxTotalIdr) * 100
                    percentage = percentage < 1 ? 1 : percentage
                }

                amountAsksCoin += coin
                amountAsksIdr += idr

                totalAsksPrice += new BigNumber(price).multipliedBy(coin).toNumber()

                const priceAverage =
                    index === 0 ? price : new BigNumber(totalAsksPrice).dividedBy(amountAsksCoin).toNumber()

                return {
                    idr,
                    price,
                    coin,
                    barPercentage: percentage,
                    priceAverage,
                    amountCoin: amountAsksCoin,
                    amountIdr: amountAsksIdr
                }
            })
            .sort((a, b) => b.price - a.price)
    }
}

const orderbookSlicer = createSlice({
    name: "orderbook",
    initialState: {
        orderbook: null as OrderBookModel | null,
        coinId: null as number | null,
        isLoading: true
    },
    reducers: {
        setOrderBook: (state, action: PayloadAction<OrderBookSocketPayload>) => {
            if (action.payload.i !== state.coinId) return
            state.orderbook = convertToModel(action.payload.bs)
        }
    },
    extraReducers: {
        [fetchOrderBooks.pending.type]: (state) => {
            state.isLoading = true
            state.coinId = null
            state.orderbook = null
        },
        [fetchOrderBooks.fulfilled.type]: (state, action: PayloadAction<FetchOrderBooksResponse>) => {
            state.orderbook = convertToModel(action.payload.data)
            state.coinId = action.payload.coinId
            state.isLoading = false
        },
        [fetchOrderBooksAsync.fulfilled.type]: (state, action: PayloadAction<FetchOrderBooksResponse>) => {
            state.orderbook = convertToModel(action.payload.data)
            state.coinId = action.payload.coinId
            state.isLoading = false
        }
    }
})

export const { setOrderBook } = orderbookSlicer.actions
export default orderbookSlicer
