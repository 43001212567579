import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface SubmitTwoFaargs {
    token: string
    otp: string
    secret: string
}

export const fetchSubmitTwoFa = createAsyncThunk<boolean, SubmitTwoFaargs>(
    "status/disableAuth",
    async ({ token, otp, secret }) => {
        const formData = new FormData()
        formData.append("token", token)
        formData.append("otp", otp)
        formData.append("scret", secret)

        const response = await baseFetchApi({
            url: "/submit2fa",
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData
        })

        return response
    }
)
