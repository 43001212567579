import { createAsyncThunk } from "@reduxjs/toolkit"
import { baseFetchApiV3 } from "@api/baseApi"

export interface CompetitionArgs {
    type: "trading" | "staking"
    id?: number
}

export const fetchCompetition = createAsyncThunk<CompetitionsApi, CompetitionArgs>(
    "competition/fetchCompetition",
    async ({ type, id }) => {
        if (id) {
            const response = await baseFetchApiV3<BaseResponseAPIV3<CompetitionsApi>>({
                url: `/mission/competitions/${type}/${id}`,
                method: "GET"
            })
            return response.data
        }
        const response = await baseFetchApiV3<BaseResponseAPIV3<CompetitionsApi>>({
            url: `/mission/competitions/${type}/latest`,
            method: "GET"
        })

        return response.data
    }
)
