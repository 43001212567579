import { useEffect } from "react"
import { useRouter } from "next/router"
import throttle from "lodash.throttle"
import { isMobile } from "react-device-detect"
import useDeepCompareEffect from "use-deep-compare-effect"

import type { UserProperties } from "@lib/moengage-analytic"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { fetchProfile } from "@redux/profile/action"
import { UserStatus } from "@type/model/profile.d"
import { getAge } from "@utils/date"
import { splitName } from "@utils/name"

import useLang from "./useLang"
import useStatusAuth from "./useStatusAuth"

const activateAnalyticsPlugin = async (userId?: string, payload?: UserProperties) => {
    const { default: analytics } = await import("@lib/analytics")
    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")

    if (userId) {
        analytics.identify(userId)
        moengageAnalytic.identify(userId, payload)

        const { default: tiktokPixelAnalytic } = await import("@lib/tiktok-pixel-analytic")

        tiktokPixelAnalytic.identify(userId, {
            email: payload?.email
        })
    }
}

type Lang = "en" | "id"

const throttled = throttle((action: Function) => action(), 500, { trailing: false })

const useProfile = () => {
    const { profile, isLoading } = useAppSelector((state) => state.profile)
    const { isLoggedIn } = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()
    const router = useRouter()
    const { updateLang } = useLang()

    // trigger identify phone number for tiktok pixel
    useStatusAuth()

    useEffect(() => {
        if (isLoggedIn) {
            ;(async () => {
                const response = await dispatch(fetchProfile()).unwrap()
                const { lang } = response

                if (lang && router.locale !== lang) {
                    throttled(() => updateLang({ lang: (router.locale || "id") as Lang }))
                }
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isLoggedIn])

    useDeepCompareEffect(() => {
        if (isLoggedIn) {
            if (profile.userId) {
                ;(async () => {
                    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                    moengageAnalytic.identify(`${profile.userId}`)
                })()
            }

            if (!isLoading) {
                const { userId, fullName, dob, city, email, status } = profile

                if (userId) {
                    const { firstName, lastName } = splitName(fullName || "")

                    activateAnalyticsPlugin(`${userId}`, {
                        first_name: firstName,
                        last_name: lastName,
                        device_type: isMobile ? "Mobile web" : "Web",
                        age: dob ? getAge(dob) : 0,
                        city,
                        email,
                        kyc_status: status === UserStatus.Verified
                    })
                }
            }
        } else {
            activateAnalyticsPlugin()
        }
    }, [profile, isLoading, isLoggedIn])

    return { profile, isLoading }
}

export default useProfile
