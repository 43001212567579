import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface RenameAliasMemberArgs {
    hash: string | undefined
    alias: string
}

export const fetchFamilymemberData = createAsyncThunk<FamilyMemberDataAPI[]>(
    "family/fetchFamilymemberData",
    async () => {
        const response = await baseFetchApiV3<BaseResponseAPIV3>({
            url: "/user/family/members",
            method: "GET"
        })

        return response.data
    }
)

export const renameAliasMemberAction = createAsyncThunk<SuccessAPI, RenameAliasMemberArgs>(
    "family/renameAliasMember",
    async ({ hash, alias }, { dispatch }) => {
        const response = await baseFetchApiV3<SuccessAPI>({
            url: `/user/family/members/${hash}`,
            method: "PATCH",
            data: {
                alias
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        dispatch(fetchFamilymemberData())
        return response
    }
)
