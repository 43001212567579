import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { paymentGuide } from "./actions"

const paymentGuideSlicer = createSlice({
    name: "paymentGuide",
    initialState: {
        paymentGuide: null as PaymentGuideModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [paymentGuide.pending.type]: (state) => {
            state.isLoading = true
        },
        [paymentGuide.fulfilled.type]: (state, action: PayloadAction<PaymentGuideModel[]>) => {
            state.isLoading = false
            state.paymentGuide = action.payload.map((item) => ({
                ...item
            }))
        },
        [paymentGuide.rejected.type]: (state) => {
            state.isLoading = false
            state.paymentGuide = null
        }
    }
})

export default paymentGuideSlicer
