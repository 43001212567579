import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchWalletUser, FetchWalletUserResponse } from "./actions"

const walletUserSlicer = createSlice({
    name: "walletUser",
    initialState: {
        walletUser: null as WallletUserModel | null,
        userId: null as number | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchWalletUser.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchWalletUser.fulfilled.type]: (state, action: PayloadAction<FetchWalletUserResponse>) => {
            state.walletUser = {
                name: action.payload.name,
                address: action.payload.address
            }

            state.userId = action.payload.userId
            state.isLoading = false
        }
    }
})

export default walletUserSlicer
