import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { transactions } from "./actions"

const transactionsSlicer = createSlice({
    name: "transactions",
    initialState: {
        transactions: null as TransactionsModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [transactions.pending.type]: (state) => {
            state.isLoading = true
            state.transactions = null
        },
        [transactions.fulfilled.type]: (state, action: PayloadAction<TransactionsAPI>) => {
            state.transactions = {
                success: Boolean(action.payload.success),
                hash: action.payload.hash
            }

            state.isLoading = false
        }
    }
})

export default transactionsSlicer
