/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchBalances } from "./actions"

const balancesSlicer = createSlice({
    name: "balances",
    initialState: {
        balances: null as BalancesModel | null,
        isLoading: false
    },
    reducers: {
        updateBalance: (state, action) => {
            state.balances = {
                ...state.balances,
                ...action.payload
            }
        }
    },
    extraReducers: {
        [fetchBalances.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBalances.fulfilled.type]: (state, action: PayloadAction<BalancesApi>) => {
            state.balances = action.payload
            state.isLoading = false
        }
    }
})

export const { updateBalance } = balancesSlicer.actions
export default balancesSlicer
