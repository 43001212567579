// eslint-disable-next-line import/no-cycle
import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchProfile = createAsyncThunk("profile/fetchProfile", async () => {
    const profile = await baseFetchApiV3<BaseResponseAPIV3<ProfileAPI>>({
        url: "/profile"
    })

    return profile.result
})
