/* eslint-disable import/no-cycle */
/* eslint-disable simple-import-sort/imports */
import { getAuth } from "@helpers/auth"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { logout, login, login2fa, loginGoogle, register, verificationOtp, loginApple, loginCallback } from "./action"

const auth = getAuth()

const authSlicer = createSlice({
    name: "auth",
    initialState: {
        token: auth.token || null,
        hash: auth.hash || null,
        isLoggedIn: auth.isLoggedIn,
        open2fa: false
    } as AuthModel,
    reducers: {
        updateAuth: (state, action: PayloadAction<AuthModel>) => {
            state.token = action.payload?.token
            state.hash = action.payload?.hash
            state.isLoggedIn = action.payload?.isLoggedIn
        },
        open2fa: (state) => {
            state.open2fa = true
        },
        close2fa: (state) => {
            state.open2fa = false
        }
    },
    extraReducers: {
        [logout.fulfilled.type]: (state) => {
            state.hash = undefined
            state.token = undefined
            state.isLoggedIn = false
        },
        [login.pending.type]: (state) => {
            state.hash = undefined
            state.token = undefined
            state.isLoggedIn = false
        },
        [login.fulfilled.type]: (state, action: PayloadAction<AuthAPI>) => {
            state.token = action.payload?.token
            state.hash = action.payload?.hash
            state.isLoggedIn = !!action.payload?.token
        },
        [login2fa.fulfilled.type]: (state, action: PayloadAction<AuthAPI>) => {
            state.hash = action.payload.hash
            state.token = action.payload.token
            state.isLoggedIn = true
        },
        [loginCallback.fulfilled.type]: (state, action: PayloadAction<AuthAPI>) => {
            state.token = action.payload?.token
            state.hash = action.payload?.hash
            state.isLoggedIn = !!action.payload?.token
        },
        [loginGoogle.fulfilled.type]: (state, action: PayloadAction<LoginGooglePayload>) => {
            state.hash = action.payload?.hash
            state.token = action.payload?.token
            state.isLoggedIn = Boolean(action.payload?.hash) && Boolean(action.payload?.token)
        },
        [loginApple.fulfilled.type]: (state, action: PayloadAction<LoginAPI>) => {
            state.hash = action.payload.hash
            state.token = action.payload?.token
            state.isLoggedIn = Boolean(action.payload?.hash) && Boolean(action.payload?.token)
        },
        [verificationOtp.fulfilled.type]: (state, action) => {
            state.token = action.payload?.token
            state.hash = action.paylolad?.hash
            state.isLoggedIn = true
        },
        [register.fulfilled.type]: (state, action: PayloadAction<AuthModel & RegisterPayload>) => {
            state.hash = action.payload?.hash
        }
    }
})

export const { updateAuth, open2fa, close2fa } = authSlicer.actions
export default authSlicer
