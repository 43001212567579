import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchSummaryBreakdownAction = createAsyncThunk<PortfolioSummaryBreakdownAPI>(
    "portfolio/fetchSummaryBreakdown",
    async () => {
        const response = await baseFetchApiV3({
            url: "/pnl/summary/breakdown"
        })

        return response.data
    }
)
