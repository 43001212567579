import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchReferralBalance = createAsyncThunk<ReferralBalanceAPI>("referral/fetchReferralBalance", async () => {
    const response = await baseFetchApi({
        url: "/affiliatebalance",
        method: "GET"
    })
    return response
})

export const withdrawReferralBalance = createAsyncThunk<SuccessV2API, ReferralWithdrawPayload>(
    "referral/withdrawReferralBalance",
    async (payload) => {
        const response = await baseFetchApi<SuccessV2API>({
            url: "/affiliatewd",
            method: "POST",
            data: payload
        })

        return response
    }
)
