// eslint-disable-next-line import/prefer-default-export
export enum WithdrawStep {
    Default = 0,
    Method = 1,
    InputAmount = 2,
    Preview = 3,
    Verification = 4,
    Success = 5,
    Request = 6,
    AddBank = 7,
    AddEwallet = 8
}
