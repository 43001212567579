import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface SummaryBarArgs {
    period: "all" | "P24H" | "P1W" | "P1M" | "P3M" | "P1Y"
}

export const fetchSummaryBarChart = createAsyncThunk<PortfolioSummaryBarAPI, SummaryBarArgs>(
    "portfolio/fetchSummaryBarChart",
    async ({ period }) => {
        const response = await baseFetchApiV3<BaseResponseAPIV3<PortfolioSummaryBarAPI>>({
            url: "/pnl/summary/bar-chart",
            params: {
                p: period
            }
        })

        return response.data
    }
)
