import { createAsyncThunk } from "@reduxjs/toolkit"
import baseFetchApiV3 from "@api/baseApiV3"

export interface CoinInformationArgs {
    coin_id: number
}

export const fetchCoinInformation = createAsyncThunk<CoinInformationApi, CoinInformationArgs>(
    "coinInformation/fetchCoinInformation",
    async ({ coin_id }) => {
        const response = await baseFetchApiV3({
            url: `/coins/${coin_id}/information`,
            method: "GET"
        })

        return response
    }
)
