/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchOTCSettings } from "./actions"

const otcSettingsSlicer = createSlice({
    name: "OTCSettings",
    initialState: {
        otcSettings: null as OTCSettingsModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchOTCSettings.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchOTCSettings.fulfilled.type]: (state, action: PayloadAction<OTCSettingsAPI>) => {
            state.otcSettings = {
                product_id: action.payload.product_id,
                minimum_order: action.payload.minimum_order,
                maximum_order: action.payload.maximum_order,
                reserve_duration: action.payload.reserve_duration,
                enable_buy: action.payload.enable_buy === 1,
                enable_sell: action.payload.enable_sell === 1
            }

            state.isLoading = false
        },
        [fetchOTCSettings.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default otcSettingsSlicer
