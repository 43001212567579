import Icons from "@components/v3/Icons"
import { useTheme } from "@emotion/react"
import { UserStatus } from "@type/model/profile.d"

interface UserStatusIconProps {
    /**
     * The information regarding user status is status 0 for Unverified, status 8 for Waiting Verification, otherwise it will be considered Verified User.
     */
    status: UserStatus
}

const UserStatusIcon: React.FC<UserStatusIconProps> = ({ status }: UserStatusIconProps) => {
    const theme = useTheme()
    const lightMode = theme.mode === "light"

    if (status === UserStatus.Unverified || status === UserStatus.Active) {
        return <Icons width={18} height={18} icon={lightMode ? "UnverifiedLight" : "UnverifiedDark"} />
    }

    if (status === UserStatus.Pending) {
        return <Icons width={18} height={18} icon={lightMode ? "WaitingLight" : "WaitingDark"} />
    }

    return <Icons width={18} height={18} icon={lightMode ? "VerifiedLight" : "VerifiedDark"} />
}

export default UserStatusIcon
