import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"

import { fetchOTCFeeds } from "./actions"

const otcFeedsSlicer = createSlice({
    name: "otcFeeds",
    initialState: {
        otcFeeds: null as OTCFeedsAPI[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchOTCFeeds.pending.type]: (state) => {
            state.isLoading = true
            state.otcFeeds = null
        },
        [fetchOTCFeeds.fulfilled.type]: (state, action: PayloadAction<OTCFeedsAPI[]>) => {
            state.otcFeeds = action.payload
            state.isLoading = false
        },
        [HYDRATE]: (state, action: PayloadAction<{ otcFeeds: { otcFeeds: OTCFeedsAPI[] } }>) => {
            if (action.payload?.otcFeeds?.otcFeeds) {
                state.otcFeeds = action.payload.otcFeeds.otcFeeds
                state.isLoading = false
            }
        }
    }
})

export default otcFeedsSlicer
