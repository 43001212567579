/* eslint-disable import/prefer-default-export */
import { i18n } from "../../next-i18next.config"

export const languages = [
    {
        code: "id",
        name: "Bahasa Indonesia"
    },
    {
        code: "en",
        name: "English"
    }
] as const

export type LanguagesCode = typeof languages[number]["code"]

export const defaultLanguage = i18n.defaultLocale
