import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getAuth } from "@helpers/auth"
import { fetchDisableAuth } from "./action"

const auth = getAuth()

const disableAuthSlicer = createSlice({
    name: "disableAuth",
    initialState: {
        token: auth.token || null,
        otp: null
    } as unknown as DisableAuthModel,
    reducers: {},
    extraReducers: {
        [fetchDisableAuth.pending.type]: (state) => {
            state.token = undefined
            state.otp = undefined
        },
        [fetchDisableAuth.fulfilled.type]: (state, action: PayloadAction<DisableAuthModel>) => {
            state.token = action.payload?.token
            state.otp = action.payload?.otp
        }
    }
})

export default disableAuthSlicer
