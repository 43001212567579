import { useRouter } from "next/router"

import { getQueryStringParam } from "@utils/url"

const useEmbedView = () => {
    const router = useRouter()

    const viewParam = getQueryStringParam("view", router.asPath)
    const isEmbed = viewParam === "embed"

    return isEmbed
}

export default useEmbedView
