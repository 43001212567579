import { createAsyncThunk } from "@reduxjs/toolkit"
import baseFetchApi from "@api/baseApi"
import { getAuth } from "@helpers/auth"

const getTransactionType = (side: number, isHideCanceled: boolean) => {
    if (side === 0) {
        if (isHideCanceled) {
            return 8
        }

        return null
    }

    if (side === 1) {
        if (isHideCanceled) {
            return 0
        }

        return 4
    }

    if (side === 2) {
        if (isHideCanceled) {
            return 1
        }

        return 5
    }

    return null
}

interface Args {
    from?: string
    end?: string
    coinId: number
    side: number
    offset: number
}

export const fetchOrderHistory = createAsyncThunk<{ data: OrderHistoryAPI; offset: number }, Args>(
    "prices/fetchOrderHistory",
    async ({ from, end, coinId, side, offset }) => {
        const auth = getAuth()

        const data: any = {
            o: offset,
            c: coinId,
            token: auth.token
        }

        if (from) data.f = from
        if (end) data.e = end

        const transactionType = getTransactionType(side, false)
        if (transactionType !== null) data.tt = transactionType

        const response = await baseFetchApi({
            url: "/historytrans3",
            method: "POST",
            data
        })

        return response
    }
)

export const fetchOrderHistoryPagination = createAsyncThunk<{ data: OrderHistoryAPI; offset: number }, Args>(
    "prices/fetchOrderHistoryPagination",
    async ({ offset, coinId }) => {
        const auth = getAuth()

        const response = await baseFetchApi({
            url: "/historytrans3",
            method: "POST",
            data: {
                o: offset,
                c: coinId,
                token: auth.token
            }
        })

        return response
    }
)
