import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchStakingBalances } from "./actions"

const stakingBalancesSlicer = createSlice({
    name: "stakingBalances",
    initialState: {
        stakingBalances: null as StakingBalanceModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchStakingBalances.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchStakingBalances.fulfilled.type]: (state, action: PayloadAction<StakingBalanceApi[]>) => {
            state.stakingBalances = action.payload.map((stakingBalance) => ({
                totalBalance: stakingBalance.total_balance,
                accumulateAvailableBalance: stakingBalance.accumulate_available_balance,
                availableBalance: stakingBalance.available_balance,
                totalEarned: stakingBalance.total_earned,
                totalTodayEarned: stakingBalance.total_today_earned,
                stake: stakingBalance.stake,
                fillStake: stakingBalance.fill_stake,
                pendingStake: stakingBalance.pending_stake,
                unStake: stakingBalance.un_stake,
                fillUnStake: stakingBalance.fill_un_stake,
                pendingUnStake: stakingBalance.pending_un_stake,
                currentApy: stakingBalance.current_apy,
                coinId: stakingBalance.coin_id,
                productName: stakingBalance.product_name,
                productCode: stakingBalance.product_code,
                order: stakingBalance.order,
                productPeriodId: stakingBalance.product_period_id
            }))
            state.isLoading = false
        }
    }
})

export default stakingBalancesSlicer
