import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { checkRecoveryPhoneHash, recoveryPhone } from "./actions"

const recoveryPhoneSlice = createSlice({
    name: "recoveryPhone",
    initialState: {
        checkHash: null as CheckRecoveryPhoneHashAPI | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [checkRecoveryPhoneHash.pending.type]: (state) => {
            state.isLoading = true
        },
        [checkRecoveryPhoneHash.fulfilled.type]: (state, action: PayloadAction<CheckRecoveryPhoneHashAPI>) => {
            state.checkHash = action.payload
            state.isLoading = false
        },
        [checkRecoveryPhoneHash.rejected.type]: (state) => {
            state.checkHash = null
            state.isLoading = false
        },
        [recoveryPhone.pending.type]: (state) => {
            state.isLoading = true
        },
        [recoveryPhone.fulfilled.type]: (state) => {
            state.checkHash = null
            state.isLoading = false
        },
        [recoveryPhone.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default recoveryPhoneSlice
