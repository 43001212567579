import React from "react"
import { toast as toasts, Toaster, ToasterProps, ToastOptions } from "react-hot-toast"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import Icons from "@components/v3/Icons"
import { css } from "@emotion/css"
import { useTheme } from "@emotion/react"
import tailwindRekuNewConfig from "@styles/tailwindThemes/tailwind-reku-new.config"

interface Props extends ToasterProps {}

const successClassName = css`
    ${tw`!bg-foreground-green dark:!bg-dark-foreground-green !text-success dark:!text-dark-success`}
`
const errorClassName = css`
    ${tw`!bg-foreground-red dark:!bg-dark-foreground-red !text-error dark:!text-dark-error `}
`

const warningClassName = css`
    ${tw`!bg-foreground-yellow dark:!bg-dark-foreground-yellow !text-warning dark:!text-dark-warning `}
`

const infoClassName = css`
    ${tw`!bg-foreground-blue dark:!bg-dark-foreground-blue !text-info dark:!text-dark-info m-0`}
`

const containerClassName = css`
    ${tw`!font-semibold !rounded-xs !bottom-[100px]`}
`

const defaultClassName = css`
    ${tw`!shadow-header !title-3`}
    @media (max-width: 640px) {
        ${tw`!p-1.5 !px-2 !title-5`}
    }
`

const defaultOptions: ToastOptions = {
    duration: 3000,
    className: defaultClassName
}

const toast = {
    success: (message: string, options?: ToastOptions) =>
        toasts(message, {
            ...defaultOptions,
            ...options,
            className: `${successClassName} ${defaultOptions.className}`,
            icon: <Icons icon='CheckCircle' className='w-5 h-5' />
        }),
    error: (message: string, options?: ToastOptions) =>
        toasts(message, {
            ...defaultOptions,
            ...options,
            className: `${errorClassName} ${defaultOptions.className}`,
            icon: <Icons icon='XCircle' className='w-5 h-5' />
        }),
    warning: (message: string, options?: ToastOptions) =>
        toasts(message, {
            ...defaultOptions,
            ...options,
            className: `${warningClassName} ${defaultOptions.className}`,
            icon: <Icons icon='AlertTriangle' className='w-5 h-5' />
        }),
    info: (message: string, options?: ToastOptions) =>
        toasts(message, {
            ...defaultOptions,
            ...options,
            className: `${infoClassName} ${defaultOptions.className}`,
            icon: <Icons icon='Information' className='w-5 h-5' />
        }),
    promise: (
        promise: Promise<any>,
        message: { loading: string; success: string; error: string },
        options?: ToastOptions
    ) => {
        toasts.promise(
            promise,
            {
                loading: message.loading,
                success: message.success,
                error: message.error
            },
            {
                ...defaultOptions,
                ...options,
                loading: {
                    className: `${successClassName} ${defaultOptions.className}`
                },
                error: {
                    className: `${errorClassName} ${defaultOptions.className}`
                },
                success: {
                    className: `${successClassName} ${defaultOptions.className}`
                }
            }
        )
    }
}

const Toast: React.FC<Props> = () => {
    const theme = useTheme()
    const isDarkMode = theme.mode === "dark"
    const isDesktop = useMediaQuery({ minWidth: 1280 })

    const defaultOptionsResponsive: ToastOptions = {
        ...defaultOptions,
        position: isDesktop ? "top-center" : "bottom-center"
    }

    return (
        <Toaster
            containerClassName={`${containerClassName} reku-new reku-toast`}
            toastOptions={{
                ...defaultOptionsResponsive,
                error: {
                    iconTheme: {
                        primary: !isDarkMode
                            ? tailwindRekuNewConfig.colors.danger.DEFAULT
                            : tailwindRekuNewConfig.colors.dark.danger.DEFAULT,
                        secondary: !isDarkMode
                            ? tailwindRekuNewConfig.colors.foreground.red
                            : tailwindRekuNewConfig.colors.dark.foreground.red
                    }
                },
                success: {
                    iconTheme: {
                        primary: !isDarkMode
                            ? tailwindRekuNewConfig.colors.success.DEFAULT
                            : tailwindRekuNewConfig.colors.dark.success.DEFAULT,
                        secondary: !isDarkMode
                            ? tailwindRekuNewConfig.colors.foreground.green
                            : tailwindRekuNewConfig.colors.dark.foreground.green
                    }
                }
            }}
        />
    )
}

export { toast }

export default Toast
