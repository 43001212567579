import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"

/* eslint-disable import/prefer-default-export */
export const getCurrentMonth = () => {
    const now = new Date()
    return now.getMonth() + 1
}

export const getYear = () => new Date().getFullYear()

export const getCurrentYear = () => {
    const now = new Date()
    return now.getFullYear()
}

export const getMonths = (year: number = getCurrentYear()) => {
    dayjs.extend(localeData)

    let months = dayjs.months() as string[]

    if (year === getCurrentYear()) {
        months = months.slice(0, getCurrentMonth())
    }

    return months
}

export const getYearFilter = () => {
    const date = new Date()
    const years = []

    for (let i = date.getFullYear(); i >= 2017; i -= 1) {
        years.push(i)
    }

    return years
}

export const getAge = (dob: string) => Math.floor(dayjs().diff(dob, "years"))
