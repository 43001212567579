/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchSettings = createAsyncThunk<SettingsAPI>("coins/fetchSettings", async () => {
    const response = await fetchApi({
        url: "/settings",
        method: "GET"
    })

    return response
})
