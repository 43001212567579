/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import blogFetchApi from "@api/blogApi"

export interface BlogDetailArgs {
    postType: "posts" | "help" | "exchange" | "faq" | "takeaway"
    slug: string
}

export const fetchBlogDetail = createAsyncThunk<BlogDetailAPI, BlogDetailArgs>(
    "blogDetail/fetchBlogDetail",
    async ({ postType, slug }) => {
        const response = await blogFetchApi({
            url: `/${postType}?_embed&slug=${slug}`,
            method: "GET"
        })

        return response.data?.[0]
    }
)
