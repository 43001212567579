import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { fetchWalletDetail } from "./actions"

const walletDetailSlicer = createSlice({
    name: "walletDetail",
    initialState: {
        walletDetail: null as WalletDetailModel | null,
        isLoading: true,
        error: null as string | null
    },
    reducers: {},
    extraReducers: {
        [fetchWalletDetail.pending.type]: (state) => {
            state.walletDetail = null
            state.isLoading = true
            state.error = null
        },
        [fetchWalletDetail.fulfilled.type]: (state, action: PayloadAction<WalletDetailAPI>) => {
            state.walletDetail = {
                id: action.payload.wid,
                address: action.payload.address,
                name: action.payload.name,
                tag: action.payload.tag,
                tagUrl: action.payload.tagurl,
                notes: action.payload.notes,
                totalBlocks: action.payload.totalblocks
            }

            state.isLoading = false
        },
        [fetchWalletDetail.rejected.type]: (state, action) => {
            state.error = action.error?.message
            state.isLoading = false
        }
    }
})

export default walletDetailSlicer
