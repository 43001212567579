import { baseFetchApiV3 } from "@api/baseApi"
import { getAuth } from "@helpers/auth"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchStatusAuth = createAsyncThunk<StatusAuthApi>("status/statusAuth", async () => {
    const auth = getAuth()
    const response = await baseFetchApiV3<BaseResponseAPIV3<StatusAuthApi>>({
        url: "/user/status-auth",
        method: "GET",
        data: {
            token: auth.token
        }
    })

    return response.data
})
