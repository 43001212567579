import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getAuth } from "@helpers/auth"
import { RemovePhoneModel } from "@type/model/remove-phone"
import { fetchRemovePhone } from "./action"

const auth = getAuth()

const removePhoneSlicer = createSlice({
    name: "removePhone",
    initialState: {
        token: auth.token || null,
        id: null,
        otp: null,
        isLoading: false
    } as unknown as RemovePhoneModel,
    reducers: {},
    extraReducers: {
        [fetchRemovePhone.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchRemovePhone.fulfilled.type]: (state, action: PayloadAction<RemovePhoneModel>) => {
            state.token = action.payload?.token
            state.id = action.payload?.id
            state.otp = action.payload?.otp
            state.isLoading = false
        },
        [fetchRemovePhone.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default removePhoneSlicer
