import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchStakingComingsoon } from "./actions"

const stakingComingsoonSlicer = createSlice({
    name: "stakingComingsoon",
    initialState: {
        stakingComingsoon: null as StakingComingSoonModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchStakingComingsoon.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchStakingComingsoon.fulfilled.type]: (state, action: PayloadAction<StakingComingSoonAPI[]>) => {
            state.stakingComingsoon = action.payload.map((comingsoon) => ({
                id: comingsoon.id,
                coinId: comingsoon.coin_id,
                name: comingsoon.product_name,
                code: comingsoon.product_code,
                logo: comingsoon.product_logo
            }))
            state.isLoading = false
        }
    }
})

export default stakingComingsoonSlicer
