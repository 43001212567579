import { PropsWithChildren } from "react"
import classNames from "classnames"
import { Transition, TransitionStatus } from "react-transition-group"
import tw, { styled } from "twin.macro"

import { ABOVE, NAVBAR } from "@const/z-index"

interface BaseOverlayProps {
    state: TransitionStatus
    order: number
}

const BaseOverlay = styled.div<BaseOverlayProps>`
    z-index: ${({ order }) => order};
    opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`

export const OverlayFullScreen = styled.div`
    ${tw`
        fixed inset-0
        h-screen w-screen overflow-hidden
        bg-background dark:bg-dark-background
        p-4
    `}

    height: 100dvh;
    z-index: ${NAVBAR + ABOVE};
`

export interface OverlayProps extends PropsWithChildren<{}> {
    in: boolean
    order?: number
    className?: string
    timeout?: number
    onClick?: () => void
}

const Overlay: React.FC<OverlayProps> = ({
    in: inProp,
    order,
    className,
    timeout = 300,
    onClick,
    children
}: OverlayProps) => (
    <Transition in={inProp} timeout={timeout} mountOnEnter unmountOnExit>
        {(state) => (
            <BaseOverlay
                className={classNames("reku-new reku-overlay", className)}
                state={state}
                order={order || 1}
                onClick={onClick}
            >
                {children}
            </BaseOverlay>
        )}
    </Transition>
)

Overlay.defaultProps = {
    order: 1,
    className: undefined,
    onClick: undefined,
    timeout: 300
}

export default Overlay
