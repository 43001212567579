import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"
import { getAuth } from "@helpers/auth"

export const fetchEwalletUser = createAsyncThunk<EwalletUserAPI[]>("ewalletUsers/fetchEwalletUser", async () => {
    const auth = getAuth()

    const response = await fetchApi({
        url: "/ewalletuser",
        method: "POST",
        data: {
            token: auth.token
        }
    })

    return response
})

export const addEwalletUser = createAsyncThunk<SuccessV2API, string>(
    "ewalletUsers/addEwalletUser",
    async (ewalletId, { dispatch }) => {
        const response = await fetchApi({
            url: "/adduserewallet",
            method: "POST",
            data: {
                ewalletid: ewalletId
            }
        })

        dispatch(fetchEwalletUser())

        return response
    }
)

export const removeEwalletUser = createAsyncThunk<SuccessV2API, string>(
    "ewalletUsers/removeEwalletUser",
    async (id, { dispatch }) => {
        const response = await fetchApi({
            url: "/removeuserewallet",
            method: "POST",
            data: {
                id
            }
        })

        dispatch(fetchEwalletUser())

        return response
    }
)
