/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { baseFetchApiV3 } from "@api/baseApi"

export const fetchBalances = createAsyncThunk<BalancesApi, string | undefined>("coins/fetchBalances", async (hash) => {
    const response = await baseFetchApiV3({
        url: hash ? `/balance/${hash}` : "/balance",
        method: "GET"
    })

    return response
})
