import { createAsyncThunk } from "@reduxjs/toolkit"

import baseFetchApi from "@api/baseApi"

export interface FetchMarketTransactionsResponse {
    data: MarketTransactionsAPI
    coinCode: string
}

export const handleFetchMarketTransactions = async (coinCode: string) => {
    const response = await baseFetchApi<MarketTransactionsAPI>({
        url: `/acctrade/${coinCode}_IDR`,
        method: "GET"
    })

    return { data: response, coinCode }
}

export const fetchMarketTransactions = createAsyncThunk<FetchMarketTransactionsResponse, string>(
    "marketTransactions/fetchMarketTransactions",
    handleFetchMarketTransactions
)

export const fetchMarketTransactionsAsync = createAsyncThunk<FetchMarketTransactionsResponse, string>(
    "marketTransactions/fetchMarketTransactionsAsync",
    handleFetchMarketTransactions
)
