import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchBanks = createAsyncThunk<BankAPI[]>("banks/fetchBanks", async () => {
    const response = await fetchApi({
        url: "/banks",
        method: "GET"
    })

    return response
})
