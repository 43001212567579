import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { fetchBlogDetail } from "./actions"

const blogDetailSlicer = createSlice({
    name: "blogDetail",
    initialState: {
        blogDetail: null as BlogDetailAPI | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchBlogDetail.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBlogDetail.fulfilled.type]: (state, action: PayloadAction<BlogDetailAPI>) => {
            state.blogDetail = action.payload
            state.isLoading = false
        },
        [HYDRATE]: (state, action: PayloadAction<{ blogDetail: { blogDetail: BlogDetailAPI } }>) => {
            if (action.payload?.blogDetail?.blogDetail) {
                state.blogDetail = action.payload.blogDetail.blogDetail
            }
        }
    }
})

export default blogDetailSlicer
