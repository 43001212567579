/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { baseFetchApiV3 } from "@api/baseApi"

export const fetchMissionPolling = createAsyncThunk<MissionPollingAPI>(
    "missionPolling/fetchMissionPolling",
    async () => {
        const response = await baseFetchApiV3<SuccessAPI<MissionPollingAPI>>({
            url: "/mission/polling/first-action",
            method: "GET"
        })

        if (!response.data) {
            throw new Error(response?.message)
        }

        return response.data
    }
)
