import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchCumulativeChartAction = createAsyncThunk<PortfolioCumulativeChartAPI>(
    "portfolio/fetchCumulativeChart",

    async () => {
        const response = await baseFetchApiV3<BaseResponseAPIV3<PortfolioCumulativeChartAPI>>({
            url: "/pnl/summary/cumulative/chart"
        })

        return response.data
    }
)
