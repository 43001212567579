import baseFetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface FetchReceiveCoinArgs {
    coinCode: string
    networkId: string
}

export interface FetchReceiveCoinResponse extends FetchReceiveCoinArgs {
    data: ReceiveCoinAPI
}

export const fetchReceiveCoin = createAsyncThunk<FetchReceiveCoinResponse, FetchReceiveCoinArgs>(
    "receiveCoin/fetchReceiveCoin",
    async ({ coinCode, networkId }) => {
        const response = await baseFetchApi({
            url: `/wallet/${coinCode}/address/${networkId}`
        })

        return {
            data: response,
            coinCode,
            networkId
        }
    }
)
