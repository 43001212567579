import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchNotifications, fetchTotalNotifications, fetchNotificationsPagination, TotalNotifications } from "./action"

export interface TotalSocket {
    t: {
        total: number
    }
}

const notificationsSlicer = createSlice({
    name: "notifications",
    initialState: {
        notifications: null as NotificationsModel[] | null,
        total: null as TotalNotifications | null,
        isLoading: false
    },
    reducers: {
        updateNotif: (state, action: PayloadAction<TotalSocket>) => {
            state.total = {
                result: action.payload.t.total
            }
        }
    },
    extraReducers: {
        [fetchNotifications.pending.type]: (state) => {
            if (!state.notifications) {
                state.isLoading = true
            }
        },
        [fetchNotifications.fulfilled.type]: (state, action: PayloadAction<NotificationsModel[]>) => {
            state.notifications = action.payload.map((notif) => ({
                message: notif.message,
                time: notif.time,
                info: notif.info
            }))

            state.isLoading = false
        },
        [fetchNotificationsPagination.fulfilled.type]: (state, action: PayloadAction<NotificationsModel[]>) => {
            state.notifications?.push(
                ...action.payload.map((notif) => ({
                    message: notif.message,
                    time: notif.time,
                    info: notif.info
                }))
            )
            state.isLoading = false
        },
        [fetchTotalNotifications.fulfilled.type]: (state, action: PayloadAction<TotalNotifications>) => {
            state.total = action.payload
        }
    }
})

export const { updateNotif } = notificationsSlicer.actions

export default notificationsSlicer
