import { Global, ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import { useEffect } from "react"

import useUserPreferences from "@hooks/useUserPreferences"
import globalStyles from "./globals"
import lightTheme from "./themes/light"
import darkTheme from "./themes/dark"
import sanitizeCss from "./sanitize"

interface ThemeProviderProps extends React.PropsWithChildren<{}> {}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }: ThemeProviderProps) => {
    const {
        data: { darkMode }
    } = useUserPreferences()

    const theme = !darkMode ? lightTheme : darkTheme

    useEffect(() => {
        ;(async () => {
            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
            moengageAnalytic.identify("", {
                theme: darkMode ? "Dark" : "Light"
            })
        })()
    }, [darkMode])

    return (
        <EmotionThemeProvider theme={theme}>
            <Global styles={sanitizeCss} />
            <Global styles={globalStyles} />
            {children}
        </EmotionThemeProvider>
    )
}

export default ThemeProvider
