import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchCumulativeChartAction } from "./actions"

const portfolioCumulativeChartSlice = createSlice({
    name: "cumulativeChart",
    initialState: {
        cumulativeChart: null as PortfolioCumulativeChartModel | null,
        cumulativeDetail: null as PortfolioCumulativeChartData | null,
        isLoading: false
    },
    reducers: {
        setHoverData: (state, action: PayloadAction<PortfolioCumulativeChartData | null>) => {
            state.cumulativeDetail = action.payload
        }
    },
    extraReducers: {
        [fetchCumulativeChartAction.pending.type]: (state) => {
            state.cumulativeChart = null
            state.cumulativeDetail = null
            state.isLoading = true
        },
        [fetchCumulativeChartAction.fulfilled.type]: (state, action: PayloadAction<PortfolioCumulativeChartAPI>) => {
            state.cumulativeChart = action.payload.map((chart) => ({
                timestamp: chart.t,
                percentage: chart.p,
                amount: chart.a
            }))
            state.cumulativeDetail = state.cumulativeChart[state.cumulativeChart.length - 1]
            state.isLoading = false
        }
    }
})

export default portfolioCumulativeChartSlice
export const { setHoverData } = portfolioCumulativeChartSlice.actions
