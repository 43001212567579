import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchBankUser } from "./actions"

const bankUserSlicer = createSlice({
    name: "bankUsers",
    initialState: {
        bankUsers: null as BankUserModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchBankUser.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBankUser.fulfilled.type]: (state, action: PayloadAction<BankUserAPI[]>) => {
            state.bankUsers = action.payload.map((bankUser) => ({
                id: bankUser.id,
                bankId: bankUser.bankid,
                name: bankUser.bankname,
                logo: bankUser.logo,
                branch: bankUser.bankbranch,
                accountName: bankUser.bankaccountname,
                accountNumber: bankUser.bankaccountnumber,
                isPrimary: bankUser.primarybank === "1",
                status: Number(bankUser.status)
            }))
            state.isLoading = false
        }
    }
})

export default bankUserSlicer
