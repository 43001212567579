import baseFetchApi, { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface SendRecoveryPhoneEmailArgs {
    email: string
    recaptcha?: string
}

export const sendRecoveryPhoneEmail = createAsyncThunk<SuccessV2API, SendRecoveryPhoneEmailArgs>(
    "recoveryPhone/sendRecoveryPhoneEmail",
    async ({ email, recaptcha }) => {
        const response = await baseFetchApiV3<SuccessV2API>({
            url: "/recoveryphone",
            method: "POST",
            data: {
                email,
                c: recaptcha || ""
            }
        })

        return response
    }
)

export const checkRecoveryPhoneHash = createAsyncThunk<CheckRecoveryPhoneHashAPI, string>(
    "recoveryPhone/checkRecoveryPhoneHash",
    async (hash) => {
        const response = baseFetchApiV3<CheckRecoveryPhoneHashAPI>({
            url: `/recoveryphone/check/${hash}`
        })

        return response
    }
)

export const recoveryPhone = createAsyncThunk<SuccessV2API, string>("recoveryPhone/recoveryPhone", async (hash) => {
    const response = baseFetchApi<SuccessV2API>({
        url: `/resetphone`,
        method: "POST",
        data: {
            h: hash,
            jenisid: 1
        }
    })

    return response
})
