/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchCoins = createAsyncThunk<CoinsApi>("coins/fetchCoins", async () => {
    const response = await fetchApi({
        url: "/coins",
        method: "GET"
    })

    return response
})
