import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { fetchPrices } from "./actions"

const pricesSlicer = createSlice({
    name: "prices",
    initialState: {
        prices: null as PricesModel | null,
        isLoading: false
    },
    reducers: {
        updatePrices: (state, action) => {
            if (!state.prices) {
                return
            }

            const data = action.payload
            const index = state.prices.findIndex((e) => e.code === data.code)

            if (!state.prices[index]) {
                return
            }

            state.prices[index] = {
                ...state.prices[index],
                open: data.open,
                low: data.low,
                high: data.high,
                changePercentage: data.changePercentage,
                volume: data.volume,
                close: data.close
            }
        }
    },
    extraReducers: {
        [fetchPrices.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchPrices.fulfilled.type]: (state, action: PayloadAction<PricesAPI>) => {
            state.prices = action.payload.map((price) => {
                let priceModel: PriceModel = {
                    name: price.n,
                    id: price.id,
                    code: price.cd,
                    open: price.o,
                    high: price.h,
                    low: price.l,
                    close: price.c,
                    volume: price.v,
                    changePercentage: price.cp
                }

                if (typeof price.tt !== "undefined") {
                    priceModel = {
                        ...priceModel,
                        transactionType: price.tt
                    }
                }

                return priceModel
            })

            state.isLoading = false
        },
        [HYDRATE]: (state, action: PayloadAction<{ prices: { prices: PricesModel } }>) => {
            if (action.payload?.prices?.prices) {
                state.prices = action.payload.prices.prices
            }
        }
    }
})

export const { updatePrices } = pricesSlicer.actions
export default pricesSlicer
