/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchStakingComingsoon = createAsyncThunk<StakingComingSoonAPI[]>(
    "staking/fetchStakingComingsoon",
    async () => {
        const response = await fetchApi({
            url: "/staking/products/coming-soon",
            method: "GET"
        })

        return response
    }
)
