/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import fetchApi from "@api/baseApi"

export const fetchAds = createAsyncThunk<AdsAPI>("ads/fetchAds", async () => {
    const response = await fetchApi({
        url: "/ads",
        method: "GET"
    })

    return response
})
