import { EnhancedStore } from "@reduxjs/toolkit"

let store: EnhancedStore

const storeRegistry = {
    register: (newStore: EnhancedStore) => {
        store = newStore
    },
    getStore: () => store
}

export default storeRegistry
