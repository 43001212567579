import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchBanks } from "./actions"

const banksSlicer = createSlice({
    name: "banks",
    initialState: {
        banks: null as BankModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchBanks.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBanks.fulfilled.type]: (state, action: PayloadAction<BankAPI[]>) => {
            state.banks = action.payload.map((bank) => ({
                id: bank.id,
                name: bank.bankname,
                status: Number(bank.status),
                logo: bank.logo
            }))
            state.isLoading = false
        }
    }
})

export default banksSlicer
